import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { DetailedOffer } from '../../types/offer';
import { useOffersContext } from '../../layouts/OffersContext';
import { BookmarkButton } from '../BookmarkButton/BookmarkButton';
import { CodeChip } from '../CodeChip/CodeChip';
import styles from './Offer.module.scss';

export const Header = ({ offer }: { offer: DetailedOffer }) => {
  const { handleGoBack } = useOffersContext();

  return (
    <div className={styles.header}>
      <div className={styles.actions}>
        <button className={styles.goBackButton} onClick={handleGoBack}>
          <ArrowIcon direction={ArrowIconDirection.LEFT} color='#F59D33' />
          <span>Назад</span>
        </button>
        <BookmarkButton id={offer.id} />
      </div>
      <div className={styles.title}>
        <CodeChip code={offer.speciality.code} size='big' />
        <span>{offer.speciality.name}</span>
      </div>
      <div className={styles.description}>
        <div className={styles.item}>{offer.qualification}</div>
        <span className={styles.dot}>•</span>
        <div className={styles.item}>{offer.speciality.code}</div>
        <span className={styles.dot}>•</span>
        <div className={styles.item}>🏛️ {offer.university.shortName}</div>
        <span className={styles.dot}>•</span>
        <div className={styles.item}>🎓 {offer.facultyName}</div>
      </div>
    </div>
  );
};
