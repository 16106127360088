import { Grid } from '@mui/material';
import { HeaderType } from '../../../components/Header/Header';
import * as React from 'react';
import App from '../../../components/app';
import ArticleCard from '../components/ArticleCard';
import { how_to_choose_high_education_en } from '../articles/en/how_to_choose_high_education';
import { how_to_choose_high_education_ua } from '../articles/ua/how_to_choose_high_education';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { settings } from '../../../theme';
import { Header } from '../../../components/Header';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  noScrollbar: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
}));
function Articles() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <App>
      <Header type={HeaderType.LIGHT} />
      <Grid
        textAlign='center'
        py='60px'
        px='20px'
        mb='30px'
        sx={{
          backgroundColor: settings.primary20,
        }}
      >
        <Typography variant='h1'>{t('blog.title')}</Typography>
        <Typography variant='body1'>{t('blog.subTitle')}</Typography>
      </Grid>
      <Grid
        container
        justifyContent='center'
        className={classes.noScrollbar}
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs='auto'>
          <ArticleCard markdowns={{ en: how_to_choose_high_education_en, ua: how_to_choose_high_education_ua }} />
        </Grid>
        {/* <Grid item xs='auto'>*/}
        {/* <ArticleCard markdowns={{ en: how_to_choose_high_education_en, ua: how_to_choose_high_education_ua }} />*/}
        {/* </Grid>*/}
      </Grid>
    </App>
  );
}
export default Articles;
