import { useEffect, useState } from 'react';
import Header from '../../../../components/Header';
import { HeaderType } from '../../../../components/Header/Header';
import { SavedOfferCard } from '../../components/SavedOfferCard/SavedOfferCard';
import styles from './SavedOffers.module.scss';

export const SavedOffers = () => {
  const [savedOffers, setSavedOffers] = useState<number[]>([]);

  useEffect(() => {
    const savedOffers = JSON.parse(localStorage.getItem('savedOffers') || '[]');
    setSavedOffers(savedOffers);
  }, []);

  return (
    <div>
      <Header type={HeaderType.LIGHT} />
      <h1 className={styles.title}>Збережені пропозиції</h1>
      <div className={styles.savedOffersWrap}>
        {savedOffers.map((offerId) => (
          <SavedOfferCard key={offerId} offerId={offerId} />
        ))}
      </div>
    </div>
  );
};
