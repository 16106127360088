import './index.scss';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import * as React from 'react';
const BurgerButton = ({ value, setValue }: { value?: boolean; setValue?: (v: boolean) => void }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!value) setIndex((i) => i + 1);
  }, [value]);

  return (
    <div
      className={cx('AppHeaderBurgerButton', { open: value, right: index % 2 === 1 })}
      onClick={() => setValue?.(!value)}
    >
      <span />
      <span />
      <div className='AppHeaderBurgerButton_Background' />
    </div>
  );
};

export default BurgerButton;
