import styles from './CheckboxTitle.module.scss';

export const CheckboxTitle = ({
  isDisabled,
  children,
  onClick,
}: {
  isDisabled?: boolean;
  children: string;
  onClick?: () => void;
}) => (
  <div className={isDisabled ? `${styles.title} ${styles.disabled}` : styles.title} onClick={onClick}>
    {children}
  </div>
);
