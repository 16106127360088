/* eslint-disable */
import cx from 'classnames';
import * as React from 'react';

import close from './close.svg';
import './index.scss';

const Input = ({ name, value, onChange }: { name?: string; value?: string; onChange: (newValue: string) => void }) => {
  const [validation, setValidation] = React.useState(false);
  const maxValue = 200;
  const minValue = 1;

  const handleEsc = (event: any) => {
    if (event.keyCode === 27) {
      event.preventDefault();
      onChange?.('');
    }
  };

  function debounce(func: any, timeout: number) {
    let timer: any;
    return (args: any) => {
      onChange(args);
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args);
      }, timeout);
    };
  }

  function checkInput(event: any) {
    if (event.length > 3 || event > maxValue || event < minValue) {
      return setValidation(true);
    }
    return setValidation(false);
  }

  const processChange = debounce(checkInput, 400);
  return (
    <div className={cx('AppInput', { filled: !!value, error: validation })}>
      <div className='Placeholder'>{name}</div>
      <input
        type='number'
        onKeyDown={handleEsc}
        value={value}
        max={maxValue}
        onChange={(event) => {
          processChange(event.target.value);
        }}
      />
      {value && (
        <span className='AppInputClear' onClick={() => onChange('')}>
          <img src={close} alt='Arrow Icon' />
        </span>
      )}
    </div>
  );
};

export default Input;
