import { basic } from '../common/constants';
import { SubFilter, FilterGroup, FilterTypes } from '../pages/Offers/types/filter';
import { API } from './constants';

export const fetchFilterData = async (filterType: FilterTypes, url: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${filterType} data`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching ${filterType} data:`, error);
    return [];
  }
};

type SpecialityApiResponse = {
  branchName: string;
  emoji: string;
  branchRelatedSpecialities: SubFilter[];
}[];

export const fetchSpecialities = async (): Promise<FilterGroup[]> => {
  try {
    const response = await fetch(`${basic.backendUrl}${API.FILTERS.SPECIALITIES}`);
    if (!response.ok) {
      throw new Error('Failed to fetch specialities data');
    }
    const data: SpecialityApiResponse = await response.json();
    const defaultFilterState = { isChecked: false, isDisabled: false, isDefault: false };
    return data.map((item, i) => ({
      id: i,
      name: item.branchName,
      code: codeRange(item.branchRelatedSpecialities),
      isNestedOpen: false,
      isOpened: false,
      ...item,
      ...defaultFilterState,
      filters: item.branchRelatedSpecialities.map((subItem) => ({
        ...subItem,
        ...defaultFilterState,
        field_id: i,
      })),
    }));
  } catch (error) {
    console.error('Error fetching specialities data:', error);
    return [];
  }
};

const codeRange = (subfilters: SubFilter[]) => {
  const codes = subfilters.map((subItem) => Number(subItem.code));
  const asCode = (code: number) => code.toString().padStart(3, '0');
  return `${asCode(Math.min(...codes))}-${asCode(Math.max(...codes))}`;
};
