import { CheckMarkIcon } from '../../icons/CheckMarkIcon';
import { useOffersContext } from '../../layouts/OffersContext';
import { Filter, FilterType } from '../../types/filter';
import { CheckboxTitle } from '../CheckboxTitle/CheckboxTitle';
import { CodeChip } from '../CodeChip/CodeChip';
import styles from './FilterCheckbox.module.scss';

type FilterCheckboxProps = {
  type: FilterType;
  item: Filter;
  choice: 'single' | 'multiple';
  code?: number | string;
  size: 'small' | 'big';
};

export const FilterCheckbox = ({ type, item, choice, code, size }: FilterCheckboxProps) => {
  const { handleChangingOfFilters, filters } = useOffersContext();

  const check = () => {
    if (choice === 'single') {
      const allFilters = filters[type].data;
      allFilters.forEach((filter) => {
        filter.isChecked = false;
      });
      handleChangingOfFilters(type, { data: { ...item, isChecked: !item.isChecked } });
      return;
    }

    handleChangingOfFilters(type, { data: { ...item, isChecked: !item.isChecked } });
  };

  return (
    <label className={code ? styles.wrapWithDesc : styles.wrapWithoutDesc}>
      <div className={styles.wrapCheckbox}>
        <input
          type='checkbox'
          onChange={check}
          className={styles.inputCheckBox}
          checked={!!item.isChecked}
          disabled={!!item.isDisabled}
        />
        <CheckMarkIcon
          className={size == 'small' ? styles.checkboxSmall : styles.checkboxBig}
          data-radio={choice === 'single'}
        />
        {code && (
          <span style={{ marginRight: size == 'small' ? '2px' : '4px' }}>
            <CodeChip code={code} size={size} />{' '}
          </span>
        )}
      </div>
      <CheckboxTitle isDisabled={item.isDisabled}>{`${item.name}`}</CheckboxTitle>
    </label>
  );
};
