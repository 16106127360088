const API = {
  FILTERS: {
    REGIONS: '/regions',
    SPECIALITIES: '/specialities',
    QUALIFICATIONS: '/qualifications',
    UNIVERSITIES: '/universities',
    EDUCATION_FORMS: '/educationForms',
  },
  OFFERS: {
    SEARCH_SUBJECTS: '/offers/subjects',
    SEARCH_UNIVERSITIES: '/offers/universities',
    SEARCH_YEAR: '/offers/year',
    OFFER: '/offers',
    COUNT: '/offers/count',
  },
  STUDENTS: {
    BASE: '/students',
    LINK: '/students/link',
  },
};

export { API };
