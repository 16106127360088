import './index.scss';

import * as React from 'react';
import { Link } from 'react-router-dom';

import AppContent from '../../../components/app/content';

const MyRatingStart = () => {
  return (
    <AppContent className='MyRatingStart'>
      <h1>Дізнайся наскільки твої бали ЗНО кращі за бали інших</h1>
      <Link className='AppButtonLink' to='/rating/years'>
        Розрахувати рівень крутості
      </Link>
    </AppContent>
  );
};

export default MyRatingStart;
