/* eslint-disable */
import * as React from 'react';

import getData from './getData';

let localcache: any = null;

function useZNOData(year: number) {
  const [data, setData] = React.useState(localcache);
  React.useEffect(() => {
    let unmounted = false;
    if (data == null) {
      const res = getData(year);
      res.then((d: any) => {
        setData(d);
        localcache = d;
      });
      unmounted = !unmounted;
    }
    return () => {
      unmounted = true;
    };
  }, [year]);
  return data;
}

export default useZNOData;
