import { ReactNode } from 'react';

import { OffersContextProvider } from './OffersContext';

interface Props {
  children: ReactNode;
}

export const OffersLayout = ({ children }: Props) => {
  return <OffersContextProvider>{children}</OffersContextProvider>;
};
