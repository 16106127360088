type CheckMarkIconProps = {
  width?: string;
  height?: string;
  className: string;
};

export const CheckMarkIcon = ({ width = '15', height = '12', ...rest }: CheckMarkIconProps) => (
  <svg {...rest} width={width} height={height} viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      /* eslint-disable-next-line max-len */
      d='M15.9326 0.677476C16.3868 1.10136 16.4115 1.81324 15.9876 2.26751L6.88976 12.0175C6.67157 12.2513 6.36418 12.3813 6.04443 12.3748C5.72469 12.3683 5.42281 12.226 5.21428 11.9836L0.812104 6.8648C0.406976 6.39373 0.460436 5.68343 0.93151 5.2783C1.40258 4.87317 2.11288 4.92663 2.51801 5.39771L6.1014 9.56439L14.3425 0.732494C14.7664 0.278224 15.4783 0.253592 15.9326 0.677476Z'
      fill='white'
    />
  </svg>
);
