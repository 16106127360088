/* eslint-disable */
import cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import AppContent from '../../../../components/app/content';
import Button from '../../../../components/button';
import './index.scss';

const MyRatingList = ({
  children,
  nextLink,
  nextDisabled,
  nextLabel = 'Далі',
}: {
  children?: React.ReactNode;
  nextLink: string;
  nextDisabled?: boolean | any;
  nextLabel?: string;
}) => (
  <AppContent className='MyRatingList'>
    <div>{children}</div>
    <Link className={cx('AppButtonLink', { disabled: nextDisabled })} to={!nextDisabled ? nextLink : '#'}>
      <Button disabled={nextDisabled}>{nextLabel}</Button>
    </Link>
  </AppContent>
);

export default MyRatingList;
