import { useEffect } from 'react';
import { FilterGroup } from '../../types/filter';
import './Speciality.scss';

export const Speciality = ({ handle, type, ...filter }: any) => {
  useEffect(() => {
    if (filter.filters.some((subFilter: FilterGroup) => subFilter.isChecked)) {
      handle(type, {
        data: { ...filter, isChecked: true, isOpened: filter.isNestedOpen, showMore: false },
      });
    }
  }, []);

  const toggle = () => {
    filter.filters.forEach((subFilter: FilterGroup) =>
      handle(type, {
        data: { ...subFilter, isChecked: !filter.isChecked, isOpened: false, showMore: false },
      }),
    );
    handle(type, {
      data: { ...filter, isChecked: !filter.isChecked, isOpened: filter.isNestedOpen, showMore: false },
    });
  };

  return (
    <div className='Speciality' data-selected={filter.isChecked} onClick={toggle}>
      <div className='Icon'>{filter.emoji}</div>
      <p>{filter.name}</p>
      <div className='Code'>{filter.code}</div>
    </div>
  );
};
