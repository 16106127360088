/* eslint-disable */
function copyToClipboard(textToCopy: any, setCopied: any) {
  if (navigator.clipboard && window.isSecureContext) {
    setCopied(true);
    return navigator.clipboard.writeText(textToCopy);
  }

  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res: any, rej: any) => {
    setCopied(true);
    // eslint-disable-next-line no-unused-expressions
    document.execCommand('copy') ? res() : rej();
    textArea.remove();
  });
}

export default copyToClipboard;
