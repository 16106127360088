export const getParamsFromString = (type: string, paramString: string) => {
  const arrayOfParams = paramString
    .split('&')
    .map((item) => item.split('='))
    .find((item) => item.some((elem) => elem == type));
  if (arrayOfParams && type === 'scores') {
    return JSON.parse(arrayOfParams[1] || '{}');
  } else if (!arrayOfParams && type === 'scores') {
    return {};
  } else if (arrayOfParams) {
    return arrayOfParams[1].split(',');
  }
};
