/* eslint-disable */
function checkScores(scores: any) {
  const maxValue = 200;
  const minValue = 1;
  for (let i = 0; i < scores.length; i += 1) {
    if (scores[i] === '' || scores[i].length > 3 || scores[i] > maxValue || scores[i] < minValue) {
      return true;
    }
  }
  return false;
}

export default checkScores;
