import { forwardRef, HTMLProps } from 'react';
import { SearchIcon } from '../../icons/SearchIcon';
import styles from './Search.module.scss';

type SearchProps = HTMLProps<HTMLInputElement>;

export const Search = forwardRef<HTMLInputElement, SearchProps>(function Search(props, ref) {
  return (
    <div className={styles.inputWrap}>
      <input ref={ref} className={styles.input} type='text' {...props} />
      <div className={styles.iconWrap}>
        <SearchIcon />
      </div>
    </div>
  );
});
