/* eslint-disable */
import i18n from './common/i18n';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.scss';

console.log('%cОтакої, добрий день!', 'font-weight: bold; font-size: 18px;');
console.log('%cЯ бачу ви розробник. Якщо це дійсно так, то ласкаво просимо в нашу команду!', 'font-size: 14px;');
console.log('%chttps://t.me/VladBandurin', 'font-size: 14px;');

// @ts-ignore
// CSS?.paintWorklet?.addModule?.('/smooth-corners.js');
const container: HTMLElement = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </BrowserRouter>,
);
