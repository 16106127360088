import AppRipples, { RippleColor } from '../ripple';
import * as React from 'react';
import './index.scss';

const HeaderMenuButton = ({
  emoji,
  name,
  href,
  onClick,
  target,
}: {
  emoji?: string;
  name: string;
  href?: string;
  target?: string;
  onClick?: (value: any) => void;
}) => {
  return (
    <a className='AppHeaderMenuButton' target={target} href={href} onClick={onClick} rel='noreferrer'>
      <div className='AppHeaderMenuButton_Box'>
        <img className='AppHeaderMenuButton_Emoji' src={emoji} alt='emoji' />
      </div>
      <span className='AppHeaderMenuButton_Name'>{name}</span>
      <AppRipples color={RippleColor.primary100} />
    </a>
  );
};

export default HeaderMenuButton;
