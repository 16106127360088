import Button from '../../../../components/button';
import { FilterCard } from '../../components/FilterCard/FilterCard';
import { OffersHeader } from '../../components/Header/Header';
import { InstitutionFilterCard } from '../../components/InstitutionFilterCard/InstitutionFilterCard';
import { SpecialityFilterCard } from '../../components/SpecialityFilterCard/SpecialityFilterCard';
import { useOffersContext } from '../../layouts/OffersContext';
import { FilterTypes } from '../../types/filter';
import styles from './OffersFilters.module.scss';

export const OffersFilters = () => {
  const { handleGettingResults, countOfOffers, isDisableShowMoreBtn } = useOffersContext();

  return (
    <div className={styles.offersWrap}>
      <OffersHeader />
      <div className={styles.filters}>
        <FilterCard title='Освітній рівень' type={FilterTypes.education_bases} choice='single' />
        <SpecialityFilterCard title='Галузь та спеціальність' />
        <FilterCard title='Регіони' type={FilterTypes.regions} choice='multiple' />
        <FilterCard title='Форма навчання' type={FilterTypes.education_forms} choice='multiple' />
        <FilterCard title='Бюджет чи контракт' type={FilterTypes.offer_types} choice='single' />
        <InstitutionFilterCard title='Заклад вищої освіти (ЗВО)' />
        <FilterCard title='Групувати за' type={FilterTypes.sorting} choice='single' />
      </div>

      <div className={styles.btnWrap}>
        <Button disabled={isDisableShowMoreBtn} onClick={handleGettingResults}>
          {!countOfOffers || isDisableShowMoreBtn ? 'Показати результати' : `Показати результати (${countOfOffers})`}
        </Button>
      </div>
    </div>
  );
};
