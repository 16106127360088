import { Route, Routes, useLocation } from 'react-router-dom';
import { OffersFilters } from './pages/OffersFilters/OffersFilters';
import { OffersResult } from './pages/OffersResult/OffersResult';
import { OfferPage } from './pages/OfferPage/OfferPage';
import { SavedOffers } from './pages/SavedOffers/SavedOffers';

export const OfferRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route path='/result/:id' element={<OfferPage />} />
      <Route path='/result' element={<OffersResult />} />
      <Route path='/saved' element={<SavedOffers />} />
      <Route path='*' element={<OffersFilters />} />
    </Routes>
  );
};
