import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { FilterItem, Filter, FilterGroup, UpdatedFilters, FilterType } from '../../types/filter';
import { useOffersContext } from '../../layouts/OffersContext';
import FiltersBlock from '../FilterBlock/FilterBlock';
import { FilterCheckbox } from '../FilterCheckbox/FilterCheckbox';
import { SubTitle } from '../SubTitle/SubTitle';
import styles from './FilterCard.module.scss';

const DEFAULT_ITEMS_TO_SHOW = 5;

type FilterCardProps = {
  type: FilterType;
  title: string;
  itemsToShow?: number;
  choice: 'single' | 'multiple';
};

export const FilterCard = (props: FilterCardProps) => {
  const { handleChangingOfFilters, filters } = useOffersContext();
  const filter: FilterItem = filters[props.type];

  const toggleOpen = () => {
    handleChangingOfFilters(props.type, {
      isOpened: !filter.isOpened,
    } as UpdatedFilters);
  };

  return (
    <FiltersBlock>
      <div className={styles.wrapBlock}>
        <SubTitle onClick={toggleOpen}>{props.title}</SubTitle>
        <ArrowIcon
          direction={filter.isOpened ? ArrowIconDirection.UP : ArrowIconDirection.DOWN}
          color={filter.isOpened ? '#F59D33' : '#3C474E'}
          onClick={toggleOpen}
        />
      </div>
      <Filters {...props} />
    </FiltersBlock>
  );
};

type FilterChoicesProps = {
  type: FilterType;
  itemsToShow?: number;
  choice: 'single' | 'multiple';
};

const Filters = (props: FilterChoicesProps) => {
  const { filters, handleChangingOfFilters } = useOffersContext();
  const filter: FilterItem = filters[props.type];
  const itemsToShow = props.itemsToShow || DEFAULT_ITEMS_TO_SHOW;
  const filtersDataToShow = filter.showMore ? filter.data : filter.data.slice(0, itemsToShow);
  const countOfLeftFilters = filter.showMore ? itemsToShow : filter.data.length;

  const showMore = () => {
    handleChangingOfFilters(props.type, {
      showMore: !filter.showMore,
    } as UpdatedFilters);
  };

  if (!filter.isOpened) {
    return null;
  }

  return (
    <div>
      {filtersDataToShow.map((item) => (
        <FilterCheckbox
          key={item.id}
          type={props.type}
          item={item as Filter | FilterGroup}
          choice={props.choice}
          size='big'
        />
      ))}
      {filter.showMore === false && (
        <p className={styles.showMore} onClick={showMore}>
          Показати ще {countOfLeftFilters}
        </p>
      )}
    </div>
  );
};
