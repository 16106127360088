export const how_to_choose_high_education_en = {
  markdown: `
# How to Choose Higher Education?

There are [rumors](https://osvita.ua/school/85239/) that about 50% of Ukrainians work in fields unrelated to their education...

One of the reasons is making the wrong choice of education during enrollment.

There's an issue that for around 2 years prospective students are taught how to prepare for the external independent testing (EIT) or other entrance exams, but almost no one tells them how to choose higher education correctly. As a result, when it comes to submitting applications for enrollment, students find it difficult to consciously select higher education institutions (HEIs) and majors. They often choose what they have "heard somewhere," rather than what aligns with their ambitions and abilities.

We tried to compile useful resources and advice for choosing higher education institutions and majors:

1. Choose a Major. Easier said than done. Here are resources that can help you:

    - [StudSearch.org](http://StudSearch.org) - a website with student volunteers from various HEIs and majors. You select students by major and ask them questions in personal messages. Get feedback on the quality of education, differences between majors, prospects after graduation, dormitory conditions, and much more.
    
    - [vstup.edbo.gov.ua](https://vstup.edbo.gov.ua/) - the official website with results of previous admission campaigns. Check pass rates and average scores. Try to enroll where the smartest students study. This will be your future environment that encourages your development.
    
    - [The School](https://www.youtube.com/@TheSchoolUkraine) - video interviews with students. You can hear answers to the most common questions about majors and universities.
    
    ![how_to_choose_high_education_in_ukraine_image](/images/articles/how_to_choose_high_education.png)
    
2. Choose a University. Find your Hogwarts. Here's what can help:

    - [vstup.edbo.gov.ua](https://vstup.edbo.gov.ua/) - choose a university with the highest pass rates for your major.
    
    - [УніверCheck](https://youtube.com/playlist?list=PLnrCUfP0z6YewsgXYcRInxiyWY5SSk2IU) - like "Heads or Tails," but for universities. Cool reviews of the most popular universities in Ukraine, overviews of majors.
    
    - Visit university websites, see what opportunities they offer.
    
    - Find student Telegram/Instagram channels. See what student life is like there, what student organizations exist, and what interesting activities they are engaged in.
    
    It's great when your university has many people who want to change the world for the better.
    
3. Attend open days (even online). Seeing where you'll live and study for the next 4-6 years won't hurt. Plus, you can talk to students and professors again.

4. Use the [awesome Checklist](https://cedos.org.ua/vybir/) from CEDOS.

Yes, admission is responsible and complex, but remember you're not alone. Don't be afraid to ask for help or advice. Most students and teachers will gladly answer all your pressing questions.

You're the future of our country.

Good luck in choosing the best higher education!

P.S. If you have questions or want to add something: write to us at [abitly.org@gmail.com](mailto:rabitly.org@gmail.com?subject=Abitly.org&body=Hello!%20I%20came%20from%20Abitly%20Web-Page%20and%20I%20have%20a%20question:)
`,
  subTitle: 'There are rumors that about 50% of Ukrainians work in fields unrelated to their education...',
  title: 'How to Choose Higher Education?',
  src: '/images/articles/how_to_choose_high_education.png',
  tag: 'how-to-choose-high-education-in-ukraine',
};
