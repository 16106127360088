import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

interface Props {
  markdowns: {
    [language: string]: { title: string; subTitle: string; src: string; markdown: string; tag: string };
  };
}
export default function ArticleCard({ markdowns }: Props) {
  const isSmallScreen = useMediaQuery('(max-width: 350px)');
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { title, subTitle, src, tag } = markdowns[i18n.language];

  return (
    <Card
      onClick={() => navigate(`/blog/${tag}`)}
      sx={{
        maxWidth: isSmallScreen ? 250 : 345,
        cursor: 'pointer',
      }}
    >
      <CardHeader
        title={title}
        subheader='September 14, 2022'
        titleTypographyProps={{ variant: 'h2' }}
        subheaderTypographyProps={{ variant: 'body2', fontSize: isSmallScreen ? 12 : 16 }}
      />
      <CardMedia component='img' height='194' image={src} alt={title} />
      <CardContent>
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            fontSize: isSmallScreen ? 12 : 16,
          }}
        >
          {subTitle}
        </Typography>
      </CardContent>
    </Card>
  );
}
