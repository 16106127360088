import { basic } from '../common/constants';
import { queryFrom } from '../utils/query';
import { API } from './constants';

type StudentFilters = {
  regionIds?: number[];
  universityIds?: number[];
  facultyIds?: number[];
  specialityIds?: number[];
  course?: number;
  count?: number;
  offset?: number;
};

export const getStudents = async (filters: StudentFilters) => {
  const query = queryFrom(filters);

  const response = await fetch(`${basic.studsearchUrl}${API.STUDENTS.BASE}${query}`, {
    headers: { 'content-type': 'application/json' },
  });

  return await response.json();
};

export const studentPhotoUrl = (id: string | number) => {
  return `${basic.studsearchUrl}${API.STUDENTS.BASE}/${id}/photo`;
};

export const studentLink = (studentUUID: string, social: string) => {
  return `${basic.studsearchUrl}${API.STUDENTS.LINK}/${studentUUID}/${social}`;
};
