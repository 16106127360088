import Header from '../../../../components/Header';
import { HeaderType } from '../../../../components/Header/Header';
import styles from './LoadingPage.module.scss';

type LoadingPageProps = {
  title: string;
  description?: string;
};

export const LoadingPage = (props: LoadingPageProps) => {
  return (
    <div className={styles.pageWrap}>
      <Header type={HeaderType.LIGHT} />

      <div className={styles.page}>
        <Spinner />
        <div className={styles.title}>{props.title}</div>
        {props.description && <div className={styles.description}>{props.description}</div>}
      </div>
    </div>
  );
};

const Spinner = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.loader}></div>
      <img src='/images/chill-guy.webp' className={styles.centerImage} />
    </div>
  );
};
