/* eslint-disable */
// rabotaet ne trogay
import html2canvas from 'html2canvas';

function downloadCard(index: any, setDownloadImg: any) {
  const num: any = document.querySelector(
    `div.MyRatingResultCard[data-img='${index}'] .MyRatingResultCard_Top span.MyRatingResultCard_Num`,
  );
  const el: any = document.querySelector(`div.MyRatingResultCard[data-img='${index}']`);
  num.style.display = 'none';
  el.style.borderRadius = '0px';

  html2canvas(el).then((canvas) => {
    num.style.display = 'block';
    el.style.borderRadius = '30px';

    saveAs(canvas.toDataURL(), 'your-result.png', setDownloadImg);
  });
}

function saveAs(uri: any, filename: any, setDownloadImg: any) {
  const link = document.createElement('a');

  if (typeof link.download === 'string') {
    setDownloadImg(true);
    link.href = uri;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  }
  window.open(uri);
}

export default downloadCard;
