export const q = (param: string, value: unknown) => {
  switch (typeof value) {
    case 'string':
    case 'number':
      return `${param}=${value}`;
    case 'boolean':
      return `${param}=${value ? 'true' : 'false'}`;
    case 'object':
      if (!Array.isArray(value)) {
        return '';
      }
      if (value.length === 0) {
        return '';
      }
      return `${param}=${value.join('%2C')}`; // comma separated
    case 'undefined':
      return '';
    default:
      return '';
  }
};

export const queryFrom = (object: Record<string, unknown>) => {
  const values = Object.entries(object)
    .map(([key, value]) => q(key, value))
    .filter((x) => x)
    .join('&');
  return values ? `?${values}` : '';
};
