import { useTranslation } from 'react-i18next';
import { ChipButton } from '../../../../components/ChipButton/ChipButton';
import { ArrowIcon, ArrowIconDirection } from '../../../Offers/icons/ArrowIcon';
import { useOffersContext } from '../../layouts/OffersContext';
import { FilterItem, FilterTypes } from '../../types/filter';
import './CitiesList.scss';

export const CitiesList = () => {
  const NUM_REGIONS = 6;
  const { t } = useTranslation();
  const { handleChangingOfFilters, filters } = useOffersContext();
  const filter: FilterItem = filters[FilterTypes.regions];

  return (
    <div className='CitiesContainer'>
      <div className='CitiesTitle'>
        <h3>{t('translation:home.city')}</h3>
        <a className='MoreButton' href='/offers?focus=regions'>
          {t('translation:home.more')}
          <ArrowIcon direction={ArrowIconDirection.RIGHT} color='#F59D33' />
        </a>
      </div>
      <div className='CitiesList'>
        {filter.data.slice(0, NUM_REGIONS).map((f, i) => (
          <ChipButton
            key={i}
            selected={f.isChecked}
            onClick={() => {
              const isCheckedFilter = f.isDefault ? f.isChecked : !f.isChecked;
              handleChangingOfFilters(FilterTypes.regions, {
                data: { ...f, isChecked: isCheckedFilter },
              });
            }}
          >
            {f.name}
          </ChipButton>
          // <City key={i} type={FilterTypes.regions} handle={handleChangingOfFilters} {...f} />
        ))}
      </div>
    </div>
  );
};
