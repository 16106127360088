export const how_to_choose_high_education_ua = {
  markdown: `
# Як обрати вищу освіту?


Ходять [чутки](https://osvita.ua/school/85239/), що близько 50% українців працює не за фахом…

Одна з причин - це не правильний вибір освіти під час вступу.

Є проблема, що +-2 роки абітурієнтів вчать як правильно готуватись до ЗНО чи НМТ, але майже ніхто не говорить як правильно обирати вищу освіту. 
Через це, коли діло доходить до подачі заяв на вступ, абітурієнтам складно свідомо обрати ЗВО та спеціальність. Обирають зазвичай те, що “десь колись чули”, а не те що співпадає їх амбіціям та здібностям. 

Ми спробували зібрати корисні ресурси та поради при виборі закладів вищої освіти та спеціальностей:

1. Обери спеціальність. Легше сказати, ніж зробити. Але ось ресурси, що тобі допоможуть:

    - [StudSearch.org](http://StudSearch.org) - cайт зі студентами-волонтерами з різних ЗВО та спеціальностей. Обираєш студентів за спеціальністтю та розпитуєш в особистих повідомленнях. Отрумуєш відгуки про якість навчання, різницю між спеціальностями, перспективи після випуску, умови в гуртожитках та багато іншого.
    
    - [vstup.edbo.gov.ua](https://vstup.edbo.gov.ua/) - офіційний сайт з результатами вступних кампаній минулих років. Подивись прохідні та середні бали. Намагайся вступити туди, де навчаються найрозумніші студенти. Це - твоє майбутнє оточення, яке буде спонукати тебе до розвитку.
    
    - [The School](https://www.youtube.com/@TheSchoolUkraine) - відео-інтерв’ю зі студентами. Можна почути відповіді на найпоширеніші питання про спеціальності та універи.
    
    ![how_to_choose_high_education_in_ukraine_image](/images/articles/how_to_choose_high_education.png)
    
2. Обери університет. Знайди свій Хогвардс. Ось що може допомогти:

    - [vstup.edbo.gov.ua](https://vstup.edbo.gov.ua/) - обирай університет з найбільшими прохідними балами на твою спеціальність.
    
    - [УніверCheck](https://youtube.com/playlist?list=PLnrCUfP0z6YewsgXYcRInxiyWY5SSk2IU) -  Як “Орел та решка”, але для універів. Круті огляди найпопулярніших університетів України, обзори на спеціальності.
    
    - Зайди на сайти університетів, подивись які можливості вони пропонують.
    
    - Знайди студентські телеграм / інстаграм канали. Подивись яке там студентське життя, які студентські організації там є та чим цікавим вони займаються. 
    
    Дуже круто, коли в твоєму універі є багато людей, які хочуть змінювати цей світ на краще.
    
3. Відвідай дні відкритих дверей (хоча б онлайн). Подивитись де тобі доведеться жити та навчатись наступні 4-6 років - точно не буде зайвим. А ще можна зайвий раз поспілкуватись зі студентами та викладачами. 

4. Скористайся [крутим CheckList-ом](https://cedos.org.ua/vybir/) від CEDOS.

Так, вступ - це відповідально та складно, але пам’ятай, що ти не на одинці. Не бійся питати та просити допомоги чи поради. Більшість студентів та викладачів з радісттю дадуть тобі відповіді на всі хвилюючі питання. 

Ти - майбутнє нашої країни.

Успіхів в виборі найкращої вищої освіти!

P.S. Залишились питання чи хочеш щось додати: пиши нам на пошту [abitly.org@gmail.com](mailto:rabitly.org@gmail.com?subject=Abitly.org&body=Привіт!%20Я%20прийшов%20з%20сайту%20Abitly%20і%20маю%20таке%20запитання:)
`,

  subTitle: 'Ходять чутки, що близько 50% українців працює не за фахом…',
  title: 'Як обрати вищу освіту?',
  src: '/images/articles/how_to_choose_high_education.png',
  tag: 'how-to-choose-high-education-in-ukraine',
};
