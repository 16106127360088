export enum EducationBasesTypes {
  BASIC_SECONDARY_EDUCATION = 30,
  COMPLETE_SECONDARY_EDUCATION = 40,
  SKILLED_WORKER = 510,
  JUNIOR_SPECIALIST = 520,
  PROFESSIONAL_JUNIOR_BACHELOR = 530,
  JUNIOR_BACHELOR = 610,
  BACHELOR = 620,
  MASTER = 640,
}
export const education_bases = [
  { name: 'Бакалавр (після 11 класів)', id: EducationBasesTypes.BACHELOR },
  { name: 'Магістр (в розробці)', id: EducationBasesTypes.MASTER },
];

export const BACHELOR_BASE_ARRAY = [
  EducationBasesTypes.BACHELOR,
  EducationBasesTypes.BASIC_SECONDARY_EDUCATION,
  EducationBasesTypes.COMPLETE_SECONDARY_EDUCATION,
  EducationBasesTypes.JUNIOR_BACHELOR,
  EducationBasesTypes.JUNIOR_SPECIALIST,
  EducationBasesTypes.PROFESSIONAL_JUNIOR_BACHELOR,
  EducationBasesTypes.SKILLED_WORKER,
].map((item) => item.toString());
