import React from 'react';
import { DetailedOffer } from '../../types/offer';
import styles from './Offer.module.scss';

export const CoefficientsCard = ({ offer }: { offer: DetailedOffer }) => {
  const subjectGroups = [
    offer.concursSubjects.filter((subject) => subject.position === 1),
    offer.concursSubjects.filter((subject) => subject.position === 2),
    offer.concursSubjects.filter((subject) => subject.position === 3),
    offer.concursSubjects.filter((subject) => subject.position === 4),
    offer.concursSubjects.filter((subject) => subject.position === null),
  ];

  return (
    <div className={styles.cardWrap}>
      <div className={styles.title}>Предмети НМТ та коєфіцієнти</div>
      <div className={styles.card}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Мін. бал</th>
              <th>Коєф.</th>
              <th>Предмети</th>
            </tr>
          </thead>
          <tbody>
            {subjectGroups.map((group, j) => (
              <React.Fragment key={j}>
                {group.map((subject, i) => (
                  <tr className={i === group.length - 1 ? styles.withBorder : styles.withoutBorder} key={`${j}-${i}`}>
                    <td>{subject.name}</td>
                    <td className={styles.bold}>{i === 0 && subject.coeff}</td>
                    <td className={styles.bold}>{(i === 0 && subject.minScore) ?? 1}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
