import { useEffect, useState } from 'react';

import { Header } from '../../../../components/Header';
import { HeaderType } from '../../../../components/Header/Header';
import { CodeChip } from '../../components/CodeChip/CodeChip';
import { FiltersChip } from '../../components/FiltersChip/FiltersChip';
import { OfferCard } from '../../components/OfferCard/OfferCard';
import { OfferGroup } from '../../components/OfferGroup/OfferGroup';
import { OfferSubGroup } from '../../components/OfferSubGroup/OfferSubGroup';
import { SortChip } from '../../components/SortChip/SortChip';
import { FilterGroup } from '../../types/filter';
import { SubjectOffer, UniversityOffer } from '../../types/offer';
import { useOffersContext } from '../../layouts/OffersContext';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import styles from './OffersResult.module.scss';
import Button from '../../../../components/button';

export const OffersResult = () => {
  const [loading, setLoading] = useState(true);
  const { handleFiltersChip, offers, fetchOffers, resetOffers } = useOffersContext();

  const isUniversityGrouping = (offers[0] as UniversityOffer)?.universityName !== undefined;

  useEffect(() => {
    async function fetchData() {
      await fetchOffers();
      setLoading(false);
    }

    fetchData();

    return () => {
      resetOffers();
    };
  }, []);

  if (loading) {
    return <LoadingPage title='Підбираємо найкращі пропозиції' description='І не страшно трішки почекати' />;
  }

  return (
    <div className={styles.offersResultWrap}>
      <div>
        <Header type={HeaderType.LIGHT} />
        <div className={styles.chipsWrap}>
          <FiltersChip handleFiltersChip={handleFiltersChip} />
          <SortChip title='Назвою закладу' sorting='universityName' />
          <SortChip title='Бюджетні місця' sorting='budgetPlaces' />
          <SortChip title='Вартість контракту' sorting='contractPrice' />
          <SortChip title='Кодом спецальності' sorting='specialityCode' />
        </div>
        <SearchInfo />
        {offers.length === 0 && <GoBackView />}
        {isUniversityGrouping ? (
          <OffersByUniversity data={offers as UniversityOffer[]} />
        ) : (
          <OffersBySubject data={offers as SubjectOffer[]} />
        )}
        <ShowMoreButton />
      </div>
    </div>
  );
};

function SearchInfo() {
  const { filters } = useOffersContext();
  const selectedRegion = filters.regions.data.find((x) => x.isChecked)?.name;
  const selectedSpecialityCodes = Array.from(
    new Set(
      filters.specialities.data
        .map((x) => x as FilterGroup)
        .flatMap((x) => x.filters.filter((y) => y.isChecked).map((y) => y.code))
        .filter((x) => x !== undefined),
    ),
  )
    .sort((a, b) => Number(a) - Number(b))
    .join(', ');

  return (
    <div className={styles.seachValues}>
      Регіон {selectedRegion}, за спеціальностями: {selectedSpecialityCodes}
    </div>
  );
}

type OffersByUniversityProps = {
  data: UniversityOffer[];
};

function OffersByUniversity(props: OffersByUniversityProps) {
  return (
    <div className={styles.cardsWrap}>
      {props.data.map((offerGroup, index) => (
        <OfferGroup key={index} title={<UniversityTitle {...offerGroup} />} {...offerGroup}>
          {offerGroup.faculties.map((faculty, index) => (
            <OfferSubGroup key={index} title={<FacultyTitle {...faculty} />} {...faculty}>
              {faculty.specialities.map((offer, index) => (
                <OfferCard key={index} title={<SpecialityTitle {...offer} />} offers={offer.offers} />
              ))}
            </OfferSubGroup>
          ))}
        </OfferGroup>
      ))}
    </div>
  );
}

type OffersBySubjectProps = {
  data: SubjectOffer[];
};

function OffersBySubject(props: OffersBySubjectProps) {
  return (
    <div className={styles.cardsWrap}>
      {props.data.map((offerGroup, index) => (
        <OfferGroup key={index} title={<SpecialityTitle {...offerGroup} />} {...offerGroup}>
          {offerGroup.universities.map((university, index) => (
            <OfferSubGroup key={index} title={<UniversityTitle {...university} />} {...university}>
              {university.faculties.map((offer, index) => (
                <OfferCard key={index} title={<FacultyTitle {...offer} />} offers={offer.offers} />
              ))}
            </OfferSubGroup>
          ))}
        </OfferGroup>
      ))}
    </div>
  );
}

function SpecialityTitle({ specialityName, specialityCode }: { specialityName: string; specialityCode: string }) {
  return (
    <>
      <CodeChip code={specialityCode} size='big' /> {specialityName}
    </>
  );
}

function FacultyTitle({ facultyName }: { facultyName: string }) {
  return <span>🎓 {facultyName}</span>;
}

function UniversityTitle({ universityName }: { universityName: string }) {
  return <span>🏛 {universityName}</span>;
}

function ShowMoreButton() {
  const { fetchOffers, offerPagination } = useOffersContext();
  const hasNextPage = (offerPagination?.count ?? 0) > (offerPagination?.limit ?? 0);

  const loadMoreOffers = async () => {
    const oldLimit = offerPagination?.limit ?? 0;
    fetchOffers({ limit: oldLimit + 15 });
  };

  if (!hasNextPage) {
    return <div className={styles.spacer}></div>;
  }

  return (
    <button className={styles.showMoreButton} onClick={loadMoreOffers}>
      Показати ще 15
    </button>
  );
}

function GoBackView() {
  const { handleFiltersChip } = useOffersContext();

  return (
    <div className={styles.goBackWrap}>
      <img className={styles.goBackImage} src='/images/no-results.jpg' alt='Не знайдено жодних пропозицій' />
      <div className={styles.goBackDescription}>
        На жаль, за даними пошуковими фільтрами не було знайдено жодної пропозиції.
      </div>
      <Button onClick={handleFiltersChip}>Повернутися до фільтрів</Button>
    </div>
  );
}
