import { SortType } from '../../../../api/offers';
import { SortIcon } from '../../icons/SortIcon';
import { useOffersContext } from '../../layouts/OffersContext';
import styles from './SortChip.module.scss';

type SortChipProps = {
  title: string;
  sorting: SortType;
};

export const SortChip = (props: SortChipProps) => {
  const { sorting, updateSorting } = useOffersContext();
  const active = sorting?.sort === props.sorting;

  const handleClick = () => {
    if (active && sorting.order == 'ASC') {
      updateSorting(props.sorting, 'DESC');
      return;
    }
    if (active && sorting.order == 'DESC') {
      updateSorting(null, 'ASC');
      return;
    }

    updateSorting(props.sorting, 'ASC');
  };

  return (
    <button type='button' onClick={handleClick} className={styles.chip} data-active={active}>
      <div className={styles.icon}>
        <SortIcon type={active ? sorting?.order ?? 'ASC' : 'ASC'} />
      </div>
      <div className={styles.title}>
        <p>{props.title}</p>
      </div>
    </button>
  );
};
