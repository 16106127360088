import { ChangeEvent, useEffect, useState } from 'react';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { FilterItem, FilterTypes, UpdatedFilters, UniversityFilter } from '../../types/filter';
import { useOffersContext } from '../../layouts/OffersContext';
import { FilterCheckbox } from '../FilterCheckbox/FilterCheckbox';
import { Search } from '../Search/Search';
import { SubTitle } from '../SubTitle/SubTitle';
import styles from './InstitutionFilterCard.module.scss';

type InstitutionFilterProps = {
  title: string;
};

const DEFAULT_ITEMS_TO_SHOW = 5;

export const InstitutionFilterCard = (props: InstitutionFilterProps) => {
  const { handleChangingOfFilters, filters } = useOffersContext();
  const filter: FilterItem = filters[FilterTypes.universities];
  const [searchValue, setSearchValue] = useState('');
  const [filtersAfterSearch, setFiltersAfterSearch] = useState(filter);

  useEffect(() => {
    setFiltersAfterSearch(filter);
    search(searchValue);
  }, [filter]);

  const toggleOpen = () => {
    handleChangingOfFilters(FilterTypes.universities, {
      isOpened: !filter.isOpened,
    } as UpdatedFilters);
  };

  const search = (value: string) => {
    let filteredData = filter.data;
    if (value.match(/^\d\d+$/)) {
      filteredData = filter.data.filter((item) => item.id.toString().padStart(3, '0').includes(value));
    } else if (value.match(/^..+$/)) {
      const adapted = (s: string) => (s ? s.toLowerCase().replace(/ /g, '') : '');
      filteredData = filter.data
        .map((filter) => filter as UniversityFilter)
        .filter(
          (item) => adapted(item.name).includes(adapted(value)) || adapted(item.short_name).includes(adapted(value)),
        );
    }
    setFiltersAfterSearch({
      ...filter,
      showMore: filteredData.length < DEFAULT_ITEMS_TO_SHOW,
      data: filteredData,
    });
    setSearchValue(value);
  };

  return (
    <div className={styles.filterBlock}>
      <div className={styles.wrapBlock}>
        <SubTitle onClick={toggleOpen}>{props.title}</SubTitle>
        <ArrowIcon
          direction={filter.isOpened ? ArrowIconDirection.UP : ArrowIconDirection.DOWN}
          color={filter.isOpened ? '#F59D33' : '#3C474E'}
          onClick={toggleOpen}
        />
      </div>
      {filter.isOpened && (
        <Search
          placeholder='Назва закладу або код'
          value={searchValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => search(e.target.value)}
        />
      )}
      <Filters filter={filtersAfterSearch} />
    </div>
  );
};

type FiltersProps = {
  filter: FilterItem;
};

function Filters({ filter }: FiltersProps) {
  const { handleChangingOfFilters } = useOffersContext();
  const itemsToShow = DEFAULT_ITEMS_TO_SHOW;

  // move selected filters to the top
  filter.data.sort((a, b) => {
    if (a.isChecked && !b.isChecked) {
      return -1;
    }
    if (!a.isChecked && b.isChecked) {
      return 1;
    }
    return 0;
  });

  const filtersDataToShow = filter.showMore ? filter.data : filter.data.slice(0, itemsToShow);
  const countOfLeftFilters = filter.showMore ? itemsToShow : filter.data.length;

  const showMore = () => {
    handleChangingOfFilters(FilterTypes.universities, {
      showMore: !filter.showMore,
    } as UpdatedFilters);
  };

  if (!filter.isOpened) {
    return null;
  }

  return (
    <div>
      {filtersDataToShow.map((item) => (
        <FilterCheckbox
          key={item.id}
          type={FilterTypes.universities}
          item={item}
          choice='multiple'
          size='big'
          code={item.id.toString().padStart(3, '0')}
        />
      ))}
      {filter.showMore === false && (
        <p className={styles.showMore} onClick={showMore}>
          Показати ще {countOfLeftFilters}
        </p>
      )}
    </div>
  );
}
