import { ReactNode } from 'react';
import styles from './OfferGroup.module.scss';

type OfferGroupProps = {
  title: ReactNode | string;
  children?: ReactNode | undefined;
};

export const OfferGroup = (props: OfferGroupProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{props.title}</div>
      {props.children}
    </div>
  );
};
