import { useTranslation } from 'react-i18next';
import App from '../../components/app';
import { HeaderType } from '../../components/Header/Header';
import { Grid } from '@mui/material';
import { settings } from '../../theme';
import Typography from '@mui/material/Typography';
import { PARTICIPANTS } from './participants';
import PersonCard from './components/PersonCard';
import * as React from 'react';
import { Header } from '../../components/Header';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  noScrollbar: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <App>
      <Header type={HeaderType.LIGHT} />
      <Grid className={classes.noScrollbar}>
        <Grid
          textAlign='center'
          py='60px'
          px='20px'
          mb='30px'
          sx={{
            backgroundColor: settings.primary20,
          }}
        >
          <Typography variant='h1'>{t('about.title')}</Typography>
          <Typography variant='body1' py={1}>
            {t('about.subTitle')}
          </Typography>
          <Typography variant='body2' py={1}>
            {t('about.projectTimelineSubTitle')}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent='center'
          sx={{ marginTop: '30px', marginBottom: '30px' }}
          className={classes.noScrollbar}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {PARTICIPANTS.map((data) => (
            <Grid key={`${data.firstName}-${data.lastName}`} sx={{ p: '20px' }}>
              <Grid key={`${data.firstName}-${data.lastName}`} item xs='auto'>
                <PersonCard data={data} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </App>
  );
};

export default AboutUs;
