/* eslint-disable */
import { ratingSubjects } from './basicSubjects';

function filterSubjects(params: any) {
  const index = params.match(/\d+/g);
  const subjects: any = [];
  for (let i = 0; i < index.length; i += 1) {
    for (let j = 0; j < ratingSubjects.length; j += 1) {
      if (j === +index[i]) {
        subjects.push(ratingSubjects[j]);
      }
    }
  }
  return subjects;
}

export default filterSubjects;
