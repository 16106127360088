import { FiltersChipIcon } from '../../icons/FiltersChipIcon';
import styles from './FiltersChip.module.scss';

export const FiltersChip = ({ handleFiltersChip }: { handleFiltersChip: () => void }) => (
  <button type='button' onClick={handleFiltersChip} className={styles.chip}>
    <div className={styles.icon}>
      <FiltersChipIcon />
    </div>
    <div className={styles.title}>
      <p>Фільтри</p>
    </div>
  </button>
);
