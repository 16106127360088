import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DetailedOffer } from '../../types/offer';
import { getOffer } from '../../../../api/offers';
import { Header } from '../../components/Offer/Header';
import { DetailsCard } from '../../components/Offer/DetailsCard';
import { EducationProgramsCard } from '../../components/Offer/EducationProgramsCard';
import { LastScoresCard } from '../../components/Offer/LastScoresCard';
import { CoefficientsCard } from '../../components/Offer/CoefficientsCard';
import { StudentFeedbackCard } from '../../components/Offer/StudentFeedbackCard';
import styles from './OfferPage.module.scss';
import { LoadingPage } from '../LoadingPage/LoadingPage';

export function OfferPage() {
  const { id } = useParams();
  const [offer, setOffer] = useState<DetailedOffer | null>();

  useEffect(() => {
    if (!id) return;

    const fetchOffer = async () => {
      const result = await getOffer(Number(id));
      setOffer(result);
    };

    fetchOffer();
  }, []);

  if (!offer) return <LoadingPage title='Пропозиції завантажуються' />;

  return (
    <div className={styles.wrap}>
      <Header offer={offer} />
      <DetailsCard offer={offer} />
      <EducationProgramsCard offer={offer} />
      <LastScoresCard offer={offer} />
      <CoefficientsCard offer={offer} />
      <StudentFeedbackCard offer={offer} />
    </div>
  );
}
