import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowIcon, ArrowIconDirection } from '../../../Offers/icons/ArrowIcon';
import { FilterItem, FilterTypes } from '../../types/filter';
import { useOffersContext } from '../../layouts/OffersContext';
import { Speciality } from '../Speciality/Speciality';
import './SpecialitiesList.scss';

const NUM_ROWS = 3;
const TOTAL_SPECIALITIES = 9;

export const SpecialitiesList = () => {
  const { t } = useTranslation();

  return (
    <div className='SpecialitiesContainer'>
      <div className='SpecialitiesTitle'>
        <h3>{t('translation:home.specialities')}</h3>
        <a className='MoreButton' href='/offers?focus=specialities'>
          {t('translation:home.more')}
          <ArrowIcon direction={ArrowIconDirection.RIGHT} color='#F59D33' />
        </a>
      </div>
      <div className='SpecialitiesList'>
        {Array.from({ length: NUM_ROWS }).map((_, i) => (
          <SpecialitiesRow key={`row-${i}`} rowIndex={i} />
        ))}
      </div>
    </div>
  );
};

function SpecialitiesRow({ rowIndex }: { rowIndex: number }) {
  const { handleChangingOfFilters, filters } = useOffersContext();
  const filter: FilterItem = filters[FilterTypes.specialities];
  const filteredSpecialities = useMemo(
    () => filter.data.slice(0, TOTAL_SPECIALITIES).filter((_, j) => j % NUM_ROWS == rowIndex),
    [filter],
  );

  return (
    <div className='SpecialitiesRow'>
      {filteredSpecialities.map((f, j) => (
        <Speciality
          key={rowIndex * NUM_ROWS + j}
          handle={handleChangingOfFilters}
          type={FilterTypes.specialities}
          {...f}
        />
      ))}
    </div>
  );
}
