import styles from './Offer.module.scss';
import { DetailedOffer } from '../../types/offer';
import { ReactChild } from 'react';

export const DetailsCard = ({ offer }: { offer: DetailedOffer }) => {
  return (
    <div className={styles.cardWrap}>
      <div className={styles.title}>Деталі</div>
      <div className={styles.card}>
        <Property title='ЗВО' value={offer.university.name} />
        <Property title='Факультет' value={offer.facultyName} />
        <Property title='Вартість навчання' value={`${offer.price} грн/рік`} />
        <Property title='Форма навчання' value={offer.educationForm} />
        <Property
          title='Кількість місць'
          value={
            <>
              <span className={styles.noBreak}>{offer.maxBudgetLicence} місць бюджет,&nbsp;</span>
              <span className={styles.noBreak}>{offer.maxLicense} - контракт,&nbsp;</span>
              <span className={styles.noBreak}>{offer.totalApplies} - кількість поданих заяв</span>
            </>
          }
        />
        <Property link={offer.sitelink} title='Посилання на офіційний сайт' value={offer.sitelink} />
      </div>
    </div>
  );
};

type PropertyProps = {
  link?: string;
  title: string;
  value: string | ReactChild;
};

const Property = (props: PropertyProps) => {
  if (props.link) {
    return (
      <div className={styles.propertyWrap}>
        <div className={styles.propertyTitle}>{props.title}</div>
        <a href={props.link} target='_blank' rel='noreferrer' className={styles.propertyLink}>
          {props.value}
        </a>
      </div>
    );
  }

  return (
    <div className={styles.propertyWrap}>
      <div className={styles.propertyTitle}>{props.title}</div>
      <div className={styles.propertyValue}>{props.value}</div>
    </div>
  );
};
