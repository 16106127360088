import './index.scss';

import cx from 'classnames';
import * as React from 'react';

const AppContent: React.FC<{
  className?: string;
  style?: React.CSSProperties;
}> = ({ children, className, style }) => (
  <div className={cx('AppContent', className)} style={style}>
    <div>{children}</div>
  </div>
);

export default AppContent;
