import * as React from 'react';
import { getOverrides, MuiMarkdown } from 'mui-markdown';

import App from '../../../components/app';
import { HeaderType } from '../../../components/Header/Header';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { how_to_choose_high_education_ua } from '../articles/ua/how_to_choose_high_education';
import { makeStyles } from '@mui/styles';
import { LANGUAGES } from '../../../common/i18n';
import { how_to_choose_high_education_en } from '../articles/en/how_to_choose_high_education';
import { Header } from '../../../components/Header';

const useStyles = makeStyles(() => ({
  noScrollbar: {
    overflow: 'auto',
    // width: '50%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
}));
const HowToChooseHighEducation = () => {
  const classes = useStyles();

  const { i18n } = useTranslation();
  const markdown =
    i18n.language === LANGUAGES.ua
      ? how_to_choose_high_education_ua.markdown
      : how_to_choose_high_education_en.markdown;

  return (
    <App>
      <Header type={HeaderType.LIGHT} />
      <Grid container flexDirection='row' justifyContent='center' my='20px' alignItems='safe center' overflow='auto'>
        <Grid item container lg={8} md={8} sm={8} py='20px' minHeight='100vh' className={classes.noScrollbar}>
          <MuiMarkdown
            overrides={{
              ...getOverrides({}), // This will keep the other default overrides.
              h1: {
                props: {
                  style: { margin: '15px' },
                },
              },
              p: {
                component: Typography,
                props: {
                  variant: 'body2',
                  style: { color: 'black', margin: '15px' },
                },
              },
              img: {
                props: {
                  style: { width: '100%' },
                },
              },
            }}
          >
            {markdown}
          </MuiMarkdown>
        </Grid>
      </Grid>
    </App>
  );
};

export default HowToChooseHighEducation;
