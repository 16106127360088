import { Route, Routes, useLocation } from 'react-router-dom';

import HowToChooseHighEducation from './pages/HowToChooseHighEducation';
import Articles from './pages/Articles';

const Blog = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route path='/how-to-choose-high-education-in-ukraine' element={<HowToChooseHighEducation />} />
      <Route path='/' element={<Articles />} />
    </Routes>
  );
};

export default Blog;
