/* eslint-disable max-len */
export const SearchIcon = ({ ...rest }) => {
  return (
    <svg {...rest} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5215 12.9688L16.459 17.0938'
        stroke='#9DA3A7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.59961 14.3438C11.0428 14.3438 13.834 11.4196 13.834 7.8125C13.834 4.20539 11.0428 1.28125 7.59961 1.28125C4.15646 1.28125 1.36523 4.20539 1.36523 7.8125C1.36523 11.4196 4.15646 14.3438 7.59961 14.3438Z'
        stroke='#9DA3A7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
