/* eslint-disable */
import * as React from 'react';
import { useParams } from 'react-router-dom';

import Input from '../../../components/input';
import checkScores from '../../../utils/checkScores';
import fillingSubjects from '../../../utils/fillingSubjects';
import filterSubjects from '../../../utils/filterSubjects';
import MyRatingHeader from '../components/myrating-header';
import MyRatingList from '../components/myrating-list';

const MyRatingSelectScorePage = () => {
  const params: any = useParams();
  const subjects: any = filterSubjects(params.subjects);
  const [scores, setScores] = React.useState(fillingSubjects(subjects));

  return (
    <>
      <MyRatingHeader
        step={3}
        stepsCount={3}
        header='Введіть бали які ви отримали з цих предметів'
        emoji='/emoji/bullseye.png'
        onBack={`/rating/subjects/${params.year}`}
      />
      <MyRatingList
        nextLink={`/rating/loading/${params.year}/${params.subjects}/${scores}`}
        nextDisabled={checkScores(scores)}
        nextLabel='Розрахувати рівень крутості'
      >
        {scores.map?.((score: any, i: number) => (
          <Input
            key={i}
            name={`${'Бал з' + ' '}${subjects[i].ratingName}`}
            value={score}
            onChange={(value: string) => {
              setScores((s: string[]) => s.map((v: string, j: number) => (i === j ? value : v)));
            }}
          />
        ))}
      </MyRatingList>
    </>
  );
};

export default MyRatingSelectScorePage;
