export enum SortingTypes {
  SPECIALITIES,
  UNIVERSITIES,
  GENERAL,
}
export const sorting = [
  {
    name: 'За спеціальностями',
    descr: 'Згрупувати пропозиції за спеціальностями',
    id: SortingTypes.SPECIALITIES,
  },
  {
    name: 'За закладом вищої освіти',
    descr: 'Згрупувати пропозиції за закладами вищої освіти',
    id: SortingTypes.UNIVERSITIES,
  },
  {
    name: 'Загальний список',
    descr: 'Відобразити всі пропозиції в загальному списку',
    id: SortingTypes.GENERAL,
  },
];
