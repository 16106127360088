/* eslint-disable */
// const nextFrame = (callback: FrameRequestCallback) => {
const nextFrame = (callback: any) => {
  if (window?.requestAnimationFrame) {
    window?.requestAnimationFrame?.(callback);
  } else {
    setTimeout(callback, 16); // 16ms ~= 60fps
  }
};
export default nextFrame;
