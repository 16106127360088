/* eslint-disable */
import * as React from 'react';
import { useParams } from 'react-router-dom';

import Checkbox from '../../../components/checkbox';
import { RatingSubject, ratingSubjects } from '../../../utils/basicSubjects';
import findIndexSubjects from '../../../utils/findIndexSubjects';
import MyRatingHeader from '../components/myrating-header';
import MyRatingList from '../components/myrating-list';

const MyRatingSelectSubjectsPage = () => {
  const params: any = useParams();
  const [subjects, setSubjects] = React.useState([ratingSubjects[0].subjectName]);

  return (
    <>
      <MyRatingHeader
        step={2}
        stepsCount={3}
        header='Оберіть предмети, які ви здававали на ЗНО'
        emoji='/emoji/books.png'
        onBack='/rating/years'
      />
      <MyRatingList
        nextLink={`/rating/scores/${params.year}/${findIndexSubjects(ratingSubjects, subjects)}`}
        nextDisabled={subjects.length <= 0}
      >
        {ratingSubjects?.map?.((subject: RatingSubject) => (
          <Checkbox
            key={subject.subjectName}
            limiter={subjects.length >= 4}
            value={subjects.includes(subject.subjectName)}
            onChange={(v) =>
              setSubjects((s: string[]) =>
                v ? [...s, subject.subjectName] : s.filter((s: string) => s !== subject.subjectName),
              )
            }
            children={subject.subjectName}
          />
        ))}
      </MyRatingList>
    </>
  );
};

export default MyRatingSelectSubjectsPage;
