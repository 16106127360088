import './index.scss';

import cx from 'classnames';
import * as React from 'react';

const Button = (
  {
    children,
    className,
    style,
    disabled,
    onClick,
  }: {
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
  }, // eslint-disable-next-line react/button-has-type
) => (
  <button className={cx('AppButton', { disabled }, className)} disabled={disabled} style={style} onClick={onClick}>
    {/* <div
      className='AppButton_Background'
    /> */}
    {children}
  </button>
);

export default Button;
