import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Pagination, SortType } from '../../../api/offers';
import { getIsFiltersDataChecked } from '../../../utils/getIsFiltersDataChecked';
import { useFilters } from '../hooks/useFilters';
import { useOffers } from '../hooks/useOffers';
import { FilterItem, FilterTypes, UpdatedFilters } from '../types/filter';
import { SubjectOffer, UniversityOffer } from '../types/offer';

export const UNIVERSITY_STORAGE_OFFERS_KEY = 'universityStorageOffers';
type OffersContextProps = {
  countOfOffers: number | undefined;
  filters: {
    sorting: FilterItem;
    education_forms: FilterItem;
    regions: FilterItem;
    specialities: FilterItem;
    offer_types: FilterItem;
    education_bases: FilterItem;
    universities: FilterItem;
  };
  filterParams: {
    education_forms: string[] | undefined;
    sorting: string[] | undefined;
    regions: string[] | undefined;
    specialities: string[] | undefined;
    offer_types: string[] | undefined;
    education_bases: string[] | undefined;
  };
  isDisableShowMoreBtn: boolean;
  offers: SubjectOffer[] | UniversityOffer[];
  offerPagination: Pagination | undefined;
  sorting:
    | {
        sort: SortType;
        order: 'ASC' | 'DESC';
      }
    | undefined;
  handleChangingOfFilters: (typeOfFilter: FilterTypes, updatedData: UpdatedFilters) => void;
  handleGettingResults: () => void;
  handleClearingOfFilters: () => void;
  handleFiltersChip: () => void;
  fetchOffers: (options?: { page?: number; limit?: number }) => void;
  handleGoBack: () => void;
  handleOfferById: (id: number) => void;
  resetOffers: () => void;
  updateSorting: (sorting: SortType, order: 'ASC' | 'DESC') => void;
} | null;

const OffersContext = createContext<OffersContextProps>(null);

interface Props {
  children: ReactNode;
}

const OffersContextProvider = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { filters, filterParams, handleChangingOfFilters, handleClearingOfFilters } = useFilters();
  const { offers, offerPagination, countOfOffers, sorting, fetchOffers, fetchOffersCount, resetOffers, updateSorting } =
    useOffers();

  const [isDisableShowMoreBtn, setIsDisableShowMoreBtn] = useState(false);

  const handleGettingResults = () => {
    navigate({
      pathname: '/offers/result',
      search: location.search,
    });
  };

  const handleFiltersChip = () => {
    navigate({
      pathname: '/offers',
      search: location.search,
    });
  };

  const handleGoBack = () => {
    const savedSearchParams = localStorage.getItem('searchParams') || '';
    navigate({
      pathname: '/offers/result',
      search: savedSearchParams,
    });
  };

  const handleOfferById = (id: number) => {
    navigate({
      pathname: `/offers/result/${id}`,
    });
  };

  useEffect(() => {
    fetchOffersCount();
  }, [filterParams]);

  useEffect(() => {
    setIsDisableShowMoreBtn(!getIsFiltersDataChecked(filters));
  }, [filters]);

  const value = useMemo(
    () => ({
      filters,
      filterParams,
      offers,
      countOfOffers,
      isDisableShowMoreBtn,
      offerPagination,
      searchParams,
      sorting,
      handleChangingOfFilters,
      handleGettingResults,
      handleClearingOfFilters,
      handleFiltersChip,
      fetchOffers,
      handleGoBack,
      handleOfferById,
      resetOffers,
      updateSorting,
    }),
    [
      filters,
      filterParams,
      offers,
      isDisableShowMoreBtn,
      offerPagination,
      countOfOffers,
      searchParams,
      sorting,
      handleChangingOfFilters,
      handleGettingResults,
      handleClearingOfFilters,
      handleFiltersChip,
      fetchOffers,
      handleGoBack,
      handleOfferById,
      resetOffers,
      updateSorting,
    ],
  );

  return <OffersContext.Provider value={value}>{children}</OffersContext.Provider>;
};

export const useOffersContext = (): NonNullable<OffersContextProps> => {
  const context = useContext(OffersContext);

  if (context === null || context === undefined) {
    throw new Error('useOffersContext was used outside of its Provider');
  }

  return context;
};

export { OffersContext, OffersContextProvider };
