import { FilterItem, FilterGroup, EXTENDED_FILTER_TYPES, FilterTypes } from '../pages/Offers/types/filter';

export const getIsFiltersDataChecked = (obj: {
  sorting: FilterItem;
  education_forms: FilterItem;
  regions: FilterItem;
  specialities: FilterItem;
  offer_types: FilterItem;
  education_bases: FilterItem;
  universities: FilterItem;
}) => {
  const verifiedFilters = new Set<string>();
  verifiedFilters.add('education_forms');
  verifiedFilters.add('universities');
  verifiedFilters.add('regions');
  for (const [filterType, filterGroup] of Object.entries(obj)) {
    if (EXTENDED_FILTER_TYPES.includes(filterType as FilterTypes)) {
      for (const filterArray of filterGroup.data) {
        const extendedFilterGroup = filterArray as FilterGroup;

        if (extendedFilterGroup.filters.some((item) => item.isChecked)) {
          verifiedFilters.add(filterType);
          continue;
        }
      }
    } else {
      if (filterGroup.data.some((item) => item.isChecked)) {
        verifiedFilters.add(filterType);
        continue;
      }
    }
  }

  return verifiedFilters.size === Object.keys(obj).length;
};
