/* eslint-disable */
function calculatesZNOPercentage(subjectId: any, mark: any, marks: any) {
  const subject: any = marks[subjectId];
  let allPupils = 0;
  let worsePupils = 0;
  for (const statmark in subject.stats) {
    allPupils += subject.stats[statmark];
    if (Number(statmark) < mark) {
      worsePupils += subject.stats[statmark];
    }
  }
  // eslint-disable-next-line no-mixed-operators
  return `${((worsePupils / allPupils) * 100).toFixed(2)}%`;
}

export default calculatesZNOPercentage;
