import { ChangeEvent, useEffect, useState } from 'react';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { FilterItem, FilterGroup, UpdatedFilters, FilterTypes } from '../../types/filter';
import { useOffersContext } from '../../layouts/OffersContext';
import { FilterCheckbox } from '../FilterCheckbox/FilterCheckbox';
import { Search } from '../Search/Search';
import { SubTitle } from '../SubTitle/SubTitle';
import styles from './SpecialityFilterCard.module.scss';

const DEFAULT_ITEMS_TO_SHOW = 5;

type SpecialityFilterCardProps = {
  title: string;
};

export const SpecialityFilterCard = (props: SpecialityFilterCardProps) => {
  const { handleChangingOfFilters, filters } = useOffersContext();
  const filter = filters[FilterTypes.specialities];
  const [searchValue, setSearchValue] = useState('');
  const [filtersAfterSearch, setFiltersAfterSearch] = useState<FilterItem>(filter);

  useEffect(() => {
    setFiltersAfterSearch(filter);
    search(searchValue);
  }, [filter]);

  const toggleOpen = () => {
    handleChangingOfFilters(FilterTypes.specialities, {
      isOpened: !filter.isOpened,
    } as UpdatedFilters);
  };

  const search = (value: string) => {
    if (value.match(/^\d\d+$/)) {
      const filteredData = filter.data
        .map((item) => item as FilterGroup)
        .filter((item) => item.filters.some((filter) => filter.code.includes(value)))
        .map((item) => ({ ...item, isNestedOpen: true }));

      setFiltersAfterSearch({
        ...filter,
        showMore: true,
        data: filteredData,
      });
    } else if (value.match(/^..+$/)) {
      const adapted = (s: string) => s.toLowerCase().replace(/ /g, '');
      const filteredData = filter.data
        .map((item) => item as FilterGroup)
        .filter((item) => item.filters.some((filter) => adapted(filter.name).includes(adapted(value))))
        .map((item) => ({
          ...item,
          isNestedOpen: true,
          filters: item.filters.filter((filter) => adapted(filter.name).includes(adapted(value))),
        }));

      setFiltersAfterSearch({
        ...filter,
        showMore: true,
        data: filteredData,
      });
    } else {
      setFiltersAfterSearch(filter);
    }
    setSearchValue(value);
  };

  return (
    <div className={styles.filterBlock}>
      <div className={styles.wrapBlock}>
        <SubTitle onClick={toggleOpen}>{props.title}</SubTitle>
        <ArrowIcon
          direction={filter.isOpened ? ArrowIconDirection.UP : ArrowIconDirection.DOWN}
          color={filter.isOpened ? '#F59D33' : '#3C474E'}
          onClick={toggleOpen}
        />
      </div>
      {filter.isOpened && (
        <Search
          placeholder='Назва спеціальності або код'
          value={searchValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => search(e.target.value)}
        />
      )}
      <SpecialityDropdownFilters filter={filtersAfterSearch} />
    </div>
  );
};

type SpecialityDropdownFiltersProps = {
  filter: FilterItem;
};

const SpecialityDropdownFilters = ({ filter }: SpecialityDropdownFiltersProps) => {
  const { handleChangingOfFilters } = useOffersContext();
  const itemsToShow = DEFAULT_ITEMS_TO_SHOW;

  const filtersDataToShow = filter.showMore ? filter.data : filter.data.slice(0, itemsToShow);
  const countOfLeftFilters = filter.showMore ? itemsToShow : filter.data.length;

  const showMore = () => {
    handleChangingOfFilters(FilterTypes.specialities, {
      showMore: !filter.showMore,
    } as UpdatedFilters);
  };

  if (!filter.isOpened) {
    return null;
  }

  return (
    <div>
      {filtersDataToShow.map((item) => (
        <SpecialityDropdown key={item.id} {...(item as FilterGroup)} />
      ))}
      {filter.showMore === false && (
        <p className={styles.showMore} onClick={showMore}>
          Показати ще {countOfLeftFilters}
        </p>
      )}
    </div>
  );
};

const SpecialityDropdown = (filter: FilterGroup) => {
  const { handleChangingOfFilters } = useOffersContext();

  const toggleOpen = () => {
    handleChangingOfFilters(FilterTypes.specialities, {
      data: { ...filter, isNestedOpen: !filter.isNestedOpen },
    });
  };

  return (
    <>
      <div className={styles.wrapDropdownBlock}>
        <SubTitle onClick={toggleOpen}>
          <div className={styles.dropdownTitleWrap}>
            <div className={styles.icon}>{filter.emoji}</div>
            <div className={styles.dropdownTextWrap}>
              <span className={styles.dropdownName}>{filter.name}</span>
              <span className={styles.dropdownCode}>{filter.code}</span>
            </div>
          </div>
        </SubTitle>
        <ArrowIcon
          direction={filter.isNestedOpen ? ArrowIconDirection.UP : ArrowIconDirection.DOWN}
          color={filter.isNestedOpen ? '#F59D33' : '#3C474E'}
          onClick={toggleOpen}
        />
      </div>
      <div className={styles.filters}>
        {filter.isNestedOpen &&
          filter.filters.map((filter) => (
            <FilterCheckbox
              key={filter.id}
              type={FilterTypes.specialities}
              item={filter as unknown as FilterGroup}
              choice='multiple'
              code={filter.code}
              size='small'
            />
          ))}
      </div>
    </>
  );
};
