import React from 'react';
import { DetailedOffer } from '../../types/offer';
import styles from './Offer.module.scss';

export const EducationProgramsCard = ({ offer }: { offer: DetailedOffer }) => {
  return (
    <div className={styles.cardWrap}>
      <div className={styles.title}>Освітні програми</div>
      <div className={styles.card}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Освітня програма</th>
            </tr>
          </thead>
          <tbody>
            {offer.educationPrograms.map((program, i) => (
              <tr key={`educationalProgram-${i}`}>
                <td>{program}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
