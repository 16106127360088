/* eslint-disable */
function findIndexSubjects(basicSubjects: any, subjects: any) {
  const index: any = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < basicSubjects.length; i++) {
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < subjects.length; j++) {
      if (basicSubjects[i].subjectName === subjects[j]) {
        index.push(i);
      }
    }
  }
  return index;
}

export default findIndexSubjects;
