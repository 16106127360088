import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CitiesList } from '../../components/CitiesList/CitiesList';
import { SpecialitiesList } from '../../components/SpecialitiesList/SpecialitiesList';
import { useOffersContext } from '../../layouts/OffersContext';
import Button from '../../../../components/button';

export const OfferWelcomePage = () => {
  const { t } = useTranslation();
  const { isDisableShowMoreBtn, handleGettingResults } = useOffersContext();

  return (
    <Grid item container lg={6} md={6} sm={12} textAlign='center' p='20px' mt='48px'>
      <Typography
        m='auto'
        variant='h2'
        maxWidth='324px'
        textAlign='center'
        justifyContent='center'
        flexWrap='wrap'
        display='flex'
      >
        {t('translation:home.title')}
      </Typography>
      <Typography variant='body2' py='10px' mb='16px'>
        {t('translation:home.subTitle')}
      </Typography>
      <SpecialitiesList />
      <CitiesList />
      <Button disabled={isDisableShowMoreBtn} onClick={handleGettingResults}>
        {t('translation:home.button')}
      </Button>
    </Grid>
  );
};
