import { ReactNode } from 'react';

import styles from './FilterBlock.module.scss';

interface AuxProps {
  children: ReactNode;
}

const FiltersBlock = ({ children }: AuxProps) => <div className={styles.filterBlock}>{children}</div>;

export default FiltersBlock;
