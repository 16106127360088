import { useEffect, useState } from 'react';
import { getStudents, studentLink, studentPhotoUrl } from '../../../../api/students';
import { DetailedOffer } from '../../types/offer';
import { Student } from '../../types/student';
import { useOffersContext } from '../../layouts/OffersContext';
import styles from './Offer.module.scss';

export const StudentFeedbackCard = ({ offer }: { offer: DetailedOffer }) => {
  const [students, setStudents] = useState<Student[]>([]);
  const { filters } = useOffersContext();

  useEffect(() => {
    const universityId = filters.universities.data.find((u) => u.name == offer.university.name)?.id;
    const fetchStudents = async () => {
      const data = await getStudents({
        specialityIds: [offer.speciality.id],
        universityIds: universityId ? [universityId] : [],
        count: 5,
      });

      setStudents(data.students);
    };

    fetchStudents();
  }, []);

  if (!students.length) return null;

  return (
    <div className={styles.cardWrap}>
      <div className={styles.title}>Студенти, які готові відповісти на твої запитання</div>
      {students.map((student) => (
        <StudentCard key={student.uuid} student={student} offer={offer} />
      ))}
    </div>
  );
};

const StudentCard = ({ student, offer }: { student: Student; offer: DetailedOffer }) => {
  const [photoSrc, setPhotoSrc] = useState<string>('/emoji/user-placeholder.svg');

  useEffect(() => {
    const url = studentPhotoUrl(student.uuid);
    fetch(url).then((res) => res.status == 200 && setPhotoSrc(url));
  }, []);

  return (
    <div className={styles.studentCard}>
      <div className={styles.studentTitleWrap}>
        <img src={photoSrc} alt={student.name} className={styles.avatar} />
        <div className={styles.studentTitle}>
          <div className={styles.studentName}>{student.name}</div>
          <div className={styles.description}>
            <div className={styles.item}>{offer.speciality.code}</div>
            <span className={styles.dot}>•</span>
            <div className={styles.item}>{offer.university.shortName}</div>
            <span className={styles.dot}>•</span>
            <div className={styles.item}>{student.course == 0 ? 1 : student.course} курс</div>
          </div>
        </div>
        <a href={studentLink(student.uuid, 'telegram')} target='_blank' rel='noindex nofollow noopener noreferrer'>
          <img src='/emoji/telegram_logo.svg' alt='telegram' className={styles.telegram} />
        </a>
      </div>
      {student.about && (
        <div className={styles.quoteWrap}>
          <img src='/emoji/quote.svg' alt='quote' className={styles.quoteImage} />
          <div className={styles.quote}>{student.about}</div>
        </div>
      )}
    </div>
  );
};
