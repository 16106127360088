export interface RatingSubject {
  subjectName: string;
  ratingName: string;
  emoji: string;
  id: number;
}

export const ratingSubjects: RatingSubject[] = [
  {
    subjectName: 'Українська мова і література',
    ratingName: 'Української мови та літератури',
    emoji: '/emoji/sunflower.png',
    id: 1,
  },
  {
    subjectName: 'Українська мова',
    ratingName: 'Української мови',
    emoji: '/emoji/ukraine.png',
    id: 29,
  },
  {
    subjectName: 'Історія України',
    ratingName: 'Історії України',
    emoji: '/emoji/scroll.png',
    id: 6,
  },
  {
    subjectName: 'Математика',
    ratingName: 'Математики',
    emoji: '/emoji/abacus.png',
    id: 14,
  },
  {
    subjectName: 'Біологія',
    ratingName: 'Біології',
    emoji: '/emoji/dna.png',
    id: 18,
  },
  {
    subjectName: 'Географія',
    ratingName: 'Географії',
    emoji: '/emoji/geography.png',
    id: 19,
  },
  {
    subjectName: 'Фізика',
    ratingName: 'Фізики',
    emoji: '/emoji/microscope.png',
    id: 21,
  },
  {
    subjectName: 'Хімія',
    ratingName: 'Хімії',
    emoji: '/emoji/testTube.png',
    id: 22,
  },
  {
    subjectName: 'Англійська мова',
    ratingName: 'Англійської мови',
    emoji: '/emoji/english.png',
    id: 30,
  },
  {
    subjectName: 'Французька мова',
    ratingName: 'Французької мови',
    emoji: '/emoji/france.png',
    id: 31,
  },
  {
    subjectName: 'Німецька мова',
    ratingName: 'Німецької мови',
    emoji: '/emoji/germany.png',
    id: 32,
  },
];
