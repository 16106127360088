import './index.scss';

import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import App from '../../components/app';
import { HeaderType } from '../../components/Header/Header';
import { MyRatingLoading, MyRatingResult } from './result';
import SelectScorePage from './select-score';
import SelectSubjectsPage from './select-subjects';
import SelectYearPage from './select-year';
import StartPage from './start';
import { Header } from '../../components/Header';

const AppContentAnimated = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();

  return (
    <div className='AppContentAnimated'>
      <CSSTransition key={location.pathname} in timeout={1000}>
        <div className='AppContentAnimated_Block'>{children}</div>
      </CSSTransition>
    </div>
  );
};

const MyRatingApp = () => {
  const location = useLocation();

  return (
    <App>
      <Header type={HeaderType.LIGHT} />
      <AppContentAnimated>
        <Routes location={location}>
          <Route path='/result/:year/:subjects/:scores' element={<MyRatingResult />} />
          <Route path='/loading/:year/:subjects/:scores' element={<MyRatingLoading />} />
          <Route path='/scores/:year/:subjects' element={<SelectScorePage />} />
          <Route path='/subjects/:year' element={<SelectSubjectsPage />} />
          <Route path='/years' element={<SelectYearPage />} />
          <Route path='*' element={<StartPage />} />
        </Routes>
      </AppContentAnimated>
    </App>
  );
};

export default MyRatingApp;
