import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import { CardActions, IconButton, useMediaQuery } from '@mui/material';
import { Participant } from '../participants';

interface Props {
  data: Participant;
}
export default function PersonCard({
  data: {
    firstName,
    lastName,
    role,
    description,
    img,
    links: { telegram, instagram, linkedIn },
  },
}: Props) {
  const isSmallScreen = useMediaQuery('(max-width: 350px)');

  const fullName = `${firstName} ${lastName}`;
  return (
    <Card
      sx={{
        maxWidth: isSmallScreen ? 250 : 345,
        cursor: 'pointer',
      }}
    >
      <CardMedia component='img' image={`/images/participants/${img}`} alt={`${fullName} - ${role}`} />
      <CardHeader
        title={fullName}
        subheader={role}
        titleTypographyProps={{ variant: 'h2' }}
        subheaderTypographyProps={{ variant: 'body2', fontSize: isSmallScreen ? 12 : 16 }}
      />
      <CardContent sx={{ pt: 0 }}>
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            fontSize: isSmallScreen ? 12 : 16,
          }}
        >
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton href={linkedIn} target='_blank' rel='noopener noreferrer'>
          <img width='20px' src='/emoji/linkedin.svg' alt={`LinkedIn of ${fullName}`} />
        </IconButton>
        {instagram && (
          <IconButton href={instagram} target='_blank' rel='noopener noreferrer'>
            <img width='20px' src='/emoji/instagram.svg' alt={`Instagram of ${fullName}`} />
          </IconButton>
        )}
        <IconButton href={telegram} target='_blank' rel='noopener noreferrer'>
          <img width='20px' src='/emoji/telegram.svg' alt={`Telegram of ${fullName}`} />
        </IconButton>
      </CardActions>
    </Card>
  );
}
