import { useNavigate } from 'react-router-dom';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { useOffersContext } from '../../layouts/OffersContext';
import { Title } from '../Title/Title';
import styles from './Header.module.scss';

export const OffersHeader = () => {
  const { isDisableShowMoreBtn: filtersSelected, handleClearingOfFilters } = useOffersContext();
  const navigate = useNavigate();

  return (
    <div className={styles.wrap}>
      <div className={styles.wrapBlock}>
        <ArrowIcon
          direction={ArrowIconDirection.LEFT}
          className={styles.arrowLeftIcon}
          color='#A7A7A7'
          onClick={() => navigate(-1)}
        />
        <Title>Фільтри</Title>
      </div>
      <div onClick={handleClearingOfFilters}>
        <p className={styles.clearButton}>{filtersSelected ? 'Обери фільтри' : 'Очистити все'}</p>
      </div>
    </div>
  );
};
