import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getOffersBySubjects,
  getOffersByUniversities,
  getOffersCount,
  Pagination,
  SortType,
  SpecialitiesFilters,
} from '../../../api/offers';
import { SortingTypes } from '../filters/sorting';
import { SubjectOffer, UniversityOffer } from '../types/offer';

export const useOffers = () => {
  const [searchParams] = useSearchParams();

  const [offers, setOffers] = useState<SubjectOffer[] | UniversityOffer[]>([]);
  const [offerPagination, setOfferPagination] = useState<Pagination | undefined>();
  const [countOfOffers, setCountOfOffers] = useState<number | undefined>();
  const [sorting, setSorting] = useState<{
    sort: SortType;
    order: 'ASC' | 'DESC';
  }>();

  const fetchOffersCount = async () => {
    const parameters: SpecialitiesFilters = {
      sort: sorting?.sort,
      order: sorting?.order ?? 'ASC',
      qualificationId: Number(searchParams.get('education_bases')),
      specialitiesIds: searchParams.get('specialities')?.split(',').map(Number) ?? [],
      regionIds: searchParams.get('regions')?.split(',').map(Number) ?? [],
      universityIds: searchParams.get('universities')?.split(',').map(Number) ?? [],
      educationFormIds: searchParams.get('education_forms')?.split(',').map(Number) ?? [],
      budgetOnly: true,
    };
    const result = await getOffersCount(parameters);
    setCountOfOffers(result.count);
  };

  const fetchOffers = async (options?: { page?: number; limit?: number }) => {
    const parameters: SpecialitiesFilters = {
      page: options?.page || undefined,
      limit: options?.limit || 15,
      sort: sorting?.sort,
      order: sorting?.order ?? 'ASC',
      qualificationId: Number(searchParams.get('education_bases')),
      specialitiesIds: searchParams.get('specialities')?.split(',').map(Number) ?? [],
      regionIds: searchParams.get('regions')?.split(',').map(Number) ?? [],
      universityIds: searchParams.get('universities')?.split(',').map(Number) ?? [],
      educationFormIds: searchParams.get('education_forms')?.split(',').map(Number) ?? [],
      budgetOnly: true,
    };
    const sortingType = Number(searchParams.get('sorting'));
    const response =
      sortingType === SortingTypes.UNIVERSITIES
        ? await getOffersByUniversities(parameters)
        : await getOffersBySubjects(parameters);
    setOffers(response.data);
    setOfferPagination(response.pagination);
  };

  const resetOffers = () => setOffers([]);

  const updateSorting = (sortingType: SortType, order: 'ASC' | 'DESC') => {
    setSorting({ sort: sortingType, order });
  };

  useEffect(() => {
    fetchOffers();
  }, [sorting]);

  return {
    countOfOffers,
    offers,
    offerPagination,
    sorting,
    fetchOffers,
    fetchOffersCount,
    resetOffers,
    updateSorting,
  };
};
