import { ReactNode } from 'react';
import { Offer } from '../../types/offer';
import { useOffersContext } from '../../layouts/OffersContext';
import { BookmarkButton } from '../BookmarkButton/BookmarkButton';
import styles from './OfferCard.module.scss';

type OfferCardProps = {
  title: ReactNode | string;
  offers: Offer[];
};

export const OfferCard = (props: OfferCardProps) => {
  const { handleOfferById } = useOffersContext();

  return (
    <>
      {props.offers.map((offer) => (
        <div key={offer.id} className={styles.card} onClick={() => handleOfferById(offer.id)}>
          <div className={styles.titleWrap}>
            <div className={styles.title}>{props.title}</div>
            <BookmarkButton id={offer.id} />
          </div>
          <div className={styles.offersWrap}>
            <Property name='Прохідний бал бюджет' value={offer.minBudgetScore} />
            <Property name='Прохідний бал контракт' value={offer.minContractScore} />
            <Property name='Бюджетні місця' value={offer.maxBudgetLicence} />
            <Property name='Вартість контракту' value={offer.price} />
            <Property name='Всього місць' value={offer.maxLicense} />
            <Property name='Кількість поданих заяв' value={offer.totalApplies} />
          </div>
        </div>
      ))}
    </>
  );
};

function Property({ name, value }: { name: string; value: string | number | undefined }) {
  return (
    <div className={styles.propertyWrap}>
      <div>{name}</div>
      <div>{value ?? '–'}</div>
    </div>
  );
}
