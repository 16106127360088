import React from 'react';

export const SortIcon = ({ type }: { type: 'ASC' | 'DESC' }) => {
  if (type === 'DESC') {
    return (
      <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.0811 10.0295H12.75M10.0811 6.41843H14.666M10.0811 2.80734H17.25M4.35423 10.5V1.5M4.35423 1.5L1.5 4.25121M4.35423 1.5L7.31745 4.25121'
          stroke='#131214'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }

  return (
    <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.0811 1.97049H16.5M10.0811 5.58157H14.666M10.0811 9.19266H12.8321M4.35423 1.5V10.5M4.35423 10.5L1.5 7.74879M4.35423 10.5L7.31745 7.74879'
        stroke='#131214'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
