import React, { PropsWithChildren } from 'react';
import './ChipButton.scss';

type ChipButtonProps = PropsWithChildren<
  {
    selected: boolean;
  } & React.HTMLProps<HTMLDivElement>
>;

export const ChipButton = ({ selected, children, ...buttonProps }: ChipButtonProps) => {
  return (
    <div className='ChipButton' data-selected={selected} {...buttonProps}>
      {children}
    </div>
  );
};
