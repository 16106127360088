import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API } from '../../../api/constants';
import { fetchFilterData, fetchSpecialities } from '../../../api/filters';
import { basic } from '../../../common/constants';
import { BACHELOR_BASE_ARRAY } from '../filters/education_bases';
import { offer_types } from '../filters/offer_types';
import { sorting, SortingTypes } from '../filters/sorting';
import {
  EXTENDED_FILTER_TYPES,
  Filter,
  FilterDataItem,
  FilterGroup,
  FilterItem,
  FilterTypes,
  SubFilter,
  UniversityFilter,
  UpdatedFilters,
} from '../types/filter';
import { getParamsFromString } from '../utils/get-params-from-string';
import { initFilterItem } from '../utils/init-filter-item.util';

export const useFilters = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [savedSearchParams, setSavedSearchParams] = useState(localStorage.getItem('searchParams') || '');

  const getSavedParams = (type: string) => {
    const params = searchParams.get(type)?.split(',');
    return params?.length ? params : getParamsFromString(type, savedSearchParams);
  };
  const filterParams = useMemo(
    () => ({
      education_forms: getSavedParams('education_forms'),
      sorting: getSavedParams('sorting'),
      regions: getSavedParams('regions'),
      specialities: getSavedParams('specialities'),
      offer_types: getSavedParams('offer_types'),
      education_bases: getSavedParams('education_bases') ?? BACHELOR_BASE_ARRAY,
      universities: getSavedParams('universities'),
    }),
    [searchParams],
  );
  const [filters, setFilters] = useState<{
    sorting: FilterItem;
    education_forms: FilterItem;
    regions: FilterItem;
    specialities: FilterItem;
    offer_types: FilterItem;
    education_bases: FilterItem;
    universities: FilterItem;
  }>({
    sorting: { isOpened: !!filterParams.sorting?.length, showMore: null, data: [] },
    education_forms: { isOpened: !!filterParams.education_forms?.length, showMore: null, data: [] },
    regions: { isOpened: !!filterParams.regions?.length, showMore: null, data: [] },
    specialities: { isOpened: !!filterParams.specialities?.length, showMore: null, data: [] },
    offer_types: { isOpened: !!filterParams.offer_types?.length, showMore: null, data: [] },
    education_bases: { isOpened: !!filterParams.education_bases?.length, showMore: null, data: [] },
    universities: { isOpened: !!filterParams.universities?.length, showMore: null, data: [] },
  });

  const getSearchParams = () => {
    const updatedFilterParams = [];
    for (const [filterType, filterGroup] of Object.entries(filters)) {
      const activeValues: FilterDataItem | number[] = [];
      if (EXTENDED_FILTER_TYPES.includes(filterType as FilterTypes)) {
        for (const filterArray of filterGroup.data) {
          const extendedFilterGroup = filterArray as FilterGroup;

          for (const option of extendedFilterGroup.filters) {
            if (option.isChecked) {
              activeValues.push(option.id);
            }
          }
        }
      } else {
        for (const item of filterGroup.data) {
          if (item.isChecked) {
            activeValues.push(item.id);
          }
        }
      }
      if (activeValues.length) {
        updatedFilterParams.push(`${filterType}=${activeValues.join(',')}`);
      }
    }

    if (savedSearchParams != updatedFilterParams.join('&')) {
      localStorage.setItem('searchParams', updatedFilterParams.join('&'));
      setSavedSearchParams(updatedFilterParams.join('&'));
    }

    return updatedFilterParams.join('&');
  };

  const handleChangingOfFilters = (typeOfFilter: FilterTypes, updatedData: UpdatedFilters) => {
    let updatedFilters;

    if (updatedData.data) {
      if (EXTENDED_FILTER_TYPES.includes(typeOfFilter) && !('filters' in updatedData.data)) {
        let indexOfFilter;
        let newFiltersArr;
        for (let i = 0; i < filters[typeOfFilter].data.length; i++) {
          indexOfFilter = (filters[typeOfFilter].data[i] as FilterGroup).filters.findIndex((obj: SubFilter) => {
            return obj.id === updatedData.data.id;
          });
          if (indexOfFilter !== -1) {
            newFiltersArr = [...(filters[typeOfFilter].data || [])];
            (newFiltersArr[i] as FilterGroup).filters[indexOfFilter] = updatedData.data;
            break;
          }
        }
        if (newFiltersArr) {
          updatedFilters = {
            ...filters,
            [typeOfFilter]: {
              ...filters[typeOfFilter],
              data: [...newFiltersArr],
            },
          };
        } else {
          updatedFilters = { ...filters };
        }
      } else {
        const indexOfFilter = filters[typeOfFilter].data.findIndex((obj: Filter | FilterGroup | UniversityFilter) => {
          return obj.id === updatedData.data.id;
        });
        const newFiltersArr = [...filters[typeOfFilter].data];
        (newFiltersArr[indexOfFilter] as FilterGroup | SubFilter) = updatedData.data;

        updatedFilters = {
          ...filters,
          [typeOfFilter]: {
            ...filters[typeOfFilter],
            data: [...newFiltersArr],
          },
        };
      }
    } else {
      updatedFilters = {
        ...filters,
        [typeOfFilter]: {
          ...filters[typeOfFilter],
          ...updatedData,
        },
      };
    }

    setFilters(updatedFilters);
  };

  const handleClearingOfFilters = async () => {
    const [education_forms, regions, specialities, education_bases, universities] = await Promise.all([
      fetchFilterData(FilterTypes.education_forms, `${basic.backendUrl}${API.FILTERS.EDUCATION_FORMS}`),
      fetchFilterData(FilterTypes.regions, `${basic.backendUrl}${API.FILTERS.REGIONS}`),
      fetchSpecialities(),
      fetchFilterData(FilterTypes.education_bases, `${basic.backendUrl}${API.FILTERS.QUALIFICATIONS}`),
      fetchFilterData(FilterTypes.universities, `${basic.backendUrl}${API.FILTERS.UNIVERSITIES}`),
    ]);
    setFilters((prevFilters) => ({
      ...prevFilters,
      education_forms: {
        ...prevFilters.education_forms,
        showMore: education_forms.length > 5 ? false : null,
        data: initFilterItem(education_forms, []),
      },
      regions: {
        ...prevFilters.regions,
        showMore: regions.length > 5 ? false : null,
        data: initFilterItem(regions, []),
      },
      specialities: {
        ...prevFilters.specialities,
        showMore: specialities.length > 5 ? false : null,
        data: initFilterItem(specialities, []),
      },
      education_bases: {
        ...prevFilters.education_bases,
        showMore: education_bases.length > 5 ? false : null,
        data: initFilterItem(education_bases, [], [education_bases[0].id]),
      },
      universities: {
        ...prevFilters.universities,
        showMore: universities.length > 5 ? false : null,
        data: initFilterItem(universities, []),
      },
      offer_types: {
        ...prevFilters.offer_types,
        showMore: offer_types.length > 5 ? false : null,
        data: initFilterItem(offer_types, [], [String(offer_types[0].id)]),
      },
      sorting: {
        isOpened: !!filterParams.sorting?.length,
        showMore: sorting.length > 5 ? false : null,
        data: initFilterItem(sorting, [], [String(SortingTypes.SPECIALITIES)]),
      },
    }));

    setSavedSearchParams('');

    navigate({
      pathname: '/offers',
    });
  };

  useEffect(() => {
    const focusedFilter = searchParams.get('focus') as FilterTypes;
    if (!focusedFilter) return;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [focusedFilter]: {
        ...prevFilters[focusedFilter],
        isOpened: true,
      },
    }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const [education_forms, regions, specialities, education_bases, universities] = await Promise.all([
        fetchFilterData(FilterTypes.education_forms, `${basic.backendUrl}${API.FILTERS.EDUCATION_FORMS}`),
        fetchFilterData(FilterTypes.regions, `${basic.backendUrl}${API.FILTERS.REGIONS}`),
        fetchSpecialities(),
        fetchFilterData(FilterTypes.education_bases, `${basic.backendUrl}${API.FILTERS.QUALIFICATIONS}`),
        fetchFilterData(FilterTypes.universities, `${basic.backendUrl}${API.FILTERS.UNIVERSITIES}`),
      ]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        education_forms: {
          ...prevFilters.education_forms,
          showMore: education_forms.length > 5 ? false : null,
          data: initFilterItem(education_forms, filterParams.education_forms),
        },
        regions: {
          ...prevFilters.regions,
          showMore: regions.length > 5 ? false : null,
          data: initFilterItem(regions, filterParams.regions),
        },
        specialities: {
          ...prevFilters.specialities,
          showMore: specialities.length > 5 ? false : null,
          data: initFilterItem(specialities, filterParams.specialities),
        },
        education_bases: {
          ...prevFilters.education_bases,
          showMore: education_bases.length > 5 ? false : null,
          data: initFilterItem(education_bases, filterParams.education_bases, [education_bases[0].id]),
        },
        universities: {
          ...prevFilters.universities,
          showMore: universities.length > 5 ? false : null,
          data: initFilterItem(universities, filterParams.universities),
        },
        offer_types: {
          ...prevFilters.offer_types,
          showMore: offer_types.length > 5 ? false : null,
          data: initFilterItem(offer_types, filterParams.offer_types, [String(offer_types[0].id)]),
        },
        sorting: {
          isOpened: !!filterParams.sorting?.length,
          showMore: sorting.length > 5 ? false : null,
          data: initFilterItem(sorting, filterParams.sorting, [String(SortingTypes.SPECIALITIES)]),
        },
      }));
    };
    fetchData();
  }, []);

  useEffect(() => {
    const searchParams = getSearchParams();
    navigate(
      {
        search: `?${searchParams}`,
      },
      { replace: true },
    );
  }, [filters]);

  return { filters, filterParams, handleChangingOfFilters, handleClearingOfFilters };
};
