import { basic } from '../common/constants';
import { DetailedOffer, SubjectOffer, UniversityOffer } from '../pages/Offers/types/offer';
import { queryFrom } from '../utils/query';
import { API } from './constants';

export type SortType = 'contractPrice' | 'budgetPlaces' | 'universityName' | 'specialityCode' | null;

export type SpecialitiesFilters = {
  page?: number;
  limit?: number;
  search?: string;
  sort?: SortType;
  order?: 'ASC' | 'DESC';
  qualificationId: number;
  specialitiesIds: number[];
  regionIds: number[];
  universityIds: number[];
  educationFormIds: number[];
  budgetOnly: boolean;
};

export type Pagination = {
  numOfPages: number;
  count: number;
  limit: number;
  page: number;
};

type OffersBySubjectResponse = {
  pagination: Pagination;
  data: SubjectOffer[];
};

type OffersByUniversitiesResponse = {
  pagination: Pagination;
  data: UniversityOffer[];
};

type OffersYearResponse = {
  year: number;
};

const getOffersBySubjects = async (data: SpecialitiesFilters): Promise<OffersBySubjectResponse> => {
  const query = queryFrom(data);

  const response = await fetch(`${basic.backendUrl}${API.OFFERS.SEARCH_SUBJECTS}${query}`, {
    headers: { 'content-type': 'application/json' },
  });

  return await response.json();
};

const getOffersByUniversities = async (data: SpecialitiesFilters): Promise<OffersByUniversitiesResponse> => {
  const query = queryFrom(data);

  const response = await fetch(`${basic.backendUrl}${API.OFFERS.SEARCH_UNIVERSITIES}${query}`, {
    headers: { 'content-type': 'application/json' },
  });

  return await response.json();
};

const getGeneralOffers = async (data: SpecialitiesFilters) => {
  const query = queryFrom(data);

  const response = await fetch(`${basic.backendUrl}${API.OFFERS.SEARCH_UNIVERSITIES}${query}`, {
    headers: { 'content-type': 'application/json' },
  });

  return await response.json();
};

const getOffersYear = async (): Promise<OffersYearResponse> => {
  const response = await fetch(basic.backendUrl + API.OFFERS.SEARCH_YEAR, {
    headers: { 'content-type': 'application/json' },
  });

  return await response.json();
};

const getOffer = async (offerId: number): Promise<DetailedOffer> => {
  const response = await fetch(`${basic.backendUrl}${API.OFFERS.OFFER}/${offerId}`, {
    headers: { 'content-type': 'application/json' },
  });

  return await response.json();
};

const getOffersCount = async (data: SpecialitiesFilters): Promise<Pagination> => {
  const query = queryFrom(data);
  const response = await fetch(`${basic.backendUrl}${API.OFFERS.COUNT}${query}`, {
    headers: { 'content-type': 'application/json' },
  });

  return await response.json();
};

export { getGeneralOffers, getOffer, getOffersBySubjects, getOffersByUniversities, getOffersCount, getOffersYear };
