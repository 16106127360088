export type FilterBase = {
  id: number;
  isChecked: boolean;
  isDisabled: boolean;
  isDefault: boolean;
};

export type Filter = {
  name: string;
  descr?: string;
  name_en?: string;
  short_name?: string;
  code?: string;
} & FilterBase;

export type SubFilter = {
  name: string;
  code: string;
  field_id: number;
} & FilterBase;

export type FilterGroup = {
  name: string;
  emoji: string;
  descr?: string;
  isOpened: boolean;
  isNestedOpen: boolean;
  code?: string;
  filters: Array<SubFilter>;
} & FilterBase;

export type UniversityFilter = {
  name: string;
  short_name: string;
  name_en: string;
  region_name: string;
} & FilterBase;

export type UpdatedFilters = {
  isOpened?: boolean;
  showMore?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

export type FilterDataItem = Filter | FilterGroup | UniversityFilter;

export type FilterItem = {
  isOpened: boolean;
  showMore: boolean | null;
  data: FilterDataItem[];
};

export enum FilterTypes {
  regions = 'regions',
  sorting = 'sorting',
  education_forms = 'education_forms',
  specialities = 'specialities',
  offer_types = 'offer_types',
  education_bases = 'education_bases',
  universities = 'universities',
}

export const EXTENDED_FILTER_TYPES = [FilterTypes.specialities];

export type FilterType = (typeof FilterTypes)[keyof typeof FilterTypes];
