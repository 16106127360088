export interface Participant {
  firstName: string;
  lastName: string;
  role: string;
  description: string;
  links: {
    telegram: string;
    linkedIn: string;
    instagram?: string;
  };
  img: string;
}

export const PARTICIPANTS: Participant[] = [
  {
    firstName: 'Dmitry',
    lastName: 'Karaush',
    role: 'CTO & Full-Stack Engineer',
    img: 'dmitry_karaush.png',
    description: 'Wrote the first line of code for this site',
    links: {
      telegram: 'https://t.me/dmytro',
      instagram: undefined,
      linkedIn: 'https://www.linkedin.com/in/dmitry-karaush-bb4290168',
    },
  },
  {
    firstName: 'Alina',
    lastName: 'Matvieieva',
    role: 'Designer & Product Owner',
    img: 'alina_matvieieva.png',
    description: 'She is best of the best designers',
    links: {
      telegram: 'https://t.me/alinamagical',
      instagram: 'https://www.instagram.com/alinamagical',
      linkedIn: 'https://www.linkedin.com/in/alina-matvieieva',
    },
  },
  {
    firstName: 'Vladyslav',
    lastName: 'Bandurin',
    role: 'Co-Founder & Data-Scientist',
    img: 'vladyslav_bandurin.png',
    description: 'Organised all this party :)',
    links: {
      telegram: 'https://t.me/VladBandurin',
      instagram: 'https://www.instagram.com/vladyslavbandurin',
      linkedIn: 'https://www.linkedin.com/in/vladislavbandurin',
    },
  },
  {
    firstName: 'Viktoriia',
    lastName: 'Hrechukha',
    img: 'viktoriia_hrechukha.png',
    role: 'COO & Full-Stack Engineer',
    description: 'Match specialists with great people',
    links: {
      telegram: 'https://t.me/vhrechukha',
      instagram: 'https://www.instagram.com/crzhel',
      linkedIn: 'https://www.linkedin.com/in/vhrechukha',
    },
  },

  {
    firstName: 'Vladyslav',
    lastName: 'Surin',
    role: 'Front-End Developer',
    description: 'He breathes life into pixels and design',
    links: {
      telegram: 'https://t.me/surinvladyslav',
      instagram: 'https://www.instagram.com/surinvladyslav',
      linkedIn: 'https://www.linkedin.com/in/surinvladyslav',
    },
    img: 'vladyslav_surin.png',
  },
  {
    firstName: 'Valeria',
    lastName: 'Proshachenko',
    role: 'Front-End Developer',
    description: 'Monday begins on Saturday',
    links: {
      telegram: 'http://t.me/Llairet',
      instagram: 'https://instagram.com/valerchixx',
      linkedIn: 'https://www.linkedin.com/in/valeria-proshachenko-71a506252',
    },
    img: 'valeria_proshachenko.png',
  },
];
