/* eslint-disable */
function fillingSubjects(subjects: string[] | number[] | any[]) {
  const scores: string[] = [];
  for (let i = 0; i < subjects.length; i += 1) {
    scores.push('');
  }
  return scores;
}

export default fillingSubjects;
