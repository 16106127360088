/* eslint-disable */
import cx from 'classnames';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import AppContent from '../../../components/app/content';
import Button from '../../../components/button';
import { HeaderMenuGroup, useAnimated } from '../../../components/Header/Header';
import { ratingSubjects } from '../../../utils/basicSubjects';
import calculatesZNOPercentage from '../../../utils/calculatesZNOPercentage';
import copyToClipboard from '../../../utils/copyToClipboard';
import downloadCard from '../../../utils/downloadCard';
import filterSubjects from '../../../utils/filterSubjects';
import findSubjectEmoji from '../../../utils/findSubjectEmoji';
import findSubjectId from '../../../utils/findSubjectId';
import useZNOData from '../../../utils/useZNOData';
import marker from '../images/marker.svg';
import './index.scss';
import { BurgerButton, HeaderMenuButton } from '../../../components/Header';

export const MyRatingLoading = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  useZNOData(+params.year);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(`/rating/result/${params.year}/${params.subjects}/${params.scores}`);
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <AppContent className='MyRatingLoading'>
      <div className='MyRatingLoadingSpinner'>
        <span>
          <span />
        </span>
        <img src='/emoji/zhdun.png' />
        <div>
          <h3>Рівень крутості розраховується️</h3>
          <p>Але тут і без розрахунків все ясно)))</p>
        </div>
      </div>
    </AppContent>
  );
};

const ResultCardChart = ({ score, data }: { score: any; data: { [score: number]: number } }) => {
  const canvas = React.useRef<HTMLCanvasElement>(null);

  const render = React.useCallback(() => {
    if (!canvas.current || !data) {
      return;
    }

    const div = canvas.current.parentElement;
    if (!div) {
      return;
    }

    const W = (canvas.current.width = div.clientWidth * window.devicePixelRatio);
    const H = (canvas.current.height = div.clientHeight * window.devicePixelRatio);
    canvas.current.style.width = `${div.clientWidth}px`;
    canvas.current.style.height = `${div.clientHeight}px`;

    const markerWidth = 21.54 * window.devicePixelRatio;
    const markerHeight = 28 * window.devicePixelRatio;
    const p = 2 * window.devicePixelRatio;
    const up = markerHeight + p + 6 * window.devicePixelRatio;
    const fontSize = 10 * window.devicePixelRatio;
    const down = fontSize;
    const w = W - p * 2;
    const h = (H - up - down) * 0.8 - p * 2;

    const ctx = canvas.current.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, W, H);

    const entries = Object.entries(data);
    entries.sort((a, b) => Number(a[0]) - Number(b[0]));

    let scoreMin = Number(entries[0][0]);
    let scoreMax = Number(entries[0][0]);
    let countMin = entries[0][1];
    let countMax = entries[0][1];
    for (const [_score, count] of entries) {
      const scoreNum = Number(_score);
      if (scoreNum < scoreMin) {
        scoreMin = scoreNum;
      }
      if (scoreNum > scoreMax) {
        scoreMax = scoreNum;
      }
      if (count < countMin) {
        countMin = count;
      }
      if (count > countMax) {
        countMax = count;
      }
    }

    ctx.beginPath();
    let scoreLow = scoreMin;
    let scoreHigh = scoreMax;
    for (let i = 0; i < entries.length; ++i) {
      const [_score, _count] = entries[i];
      const scoreNum = Number(_score);
      const x = ((scoreNum - scoreMin) / (scoreMax - scoreMin)) * w + p;
      const y = (1 - (_count - countMin) / (countMax - countMin)) * h + up;
      ctx.lineTo(x, y);

      if (scoreNum <= score && scoreNum > scoreLow) {
        scoreLow = scoreNum;
      }
      if (scoreNum >= score && scoreNum < scoreHigh) {
        scoreHigh = scoreNum;
      }
    }
    ctx.strokeStyle = '#FFFFFF';
    ctx.lineWidth = 2 * window.devicePixelRatio;
    ctx.stroke();

    ctx.lineTo(W - p, H - p);
    ctx.lineTo(p, H - p);
    ctx.closePath();

    const gradient = ctx.createLinearGradient(0, H, 0, H * 0.1);
    gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, .56)');
    ctx.fillStyle = gradient;
    ctx.fill();

    const scoreX = ((score - scoreMin) / (scoreMax - scoreMin)) * w + p;
    const scoreLowY = (1 - (data[scoreLow] - countMin) / (countMax - countMin)) * h + up;
    const scoreHighY = (1 - (data[scoreHigh] - countMin) / (countMax - countMin)) * h + up;
    const scoreY = (scoreLowY + scoreHighY) / 2;
    ctx.drawImage(
      markerImage,
      scoreX - markerWidth / 2,
      scoreY - markerHeight - 6 * window.devicePixelRatio,
      markerWidth,
      markerHeight,
    );

    const scaleHeight = 4 * window.devicePixelRatio;
    ctx.beginPath();
    ctx.moveTo(p, H - p - down - scaleHeight);
    ctx.lineTo(p, H - p - down);
    ctx.lineTo(W - p, H - p - down);
    ctx.lineTo(W - p, H - p - down - scaleHeight);
    for (let i = 0; i < 4; ++i) {
      ctx.moveTo(p + (i / 4) * w, H - p - down - scaleHeight);
      ctx.lineTo(p + (i / 4) * w, H - p - down);
    }
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)';
    ctx.lineWidth = 2 * window.devicePixelRatio;
    ctx.stroke();
    ctx.font = `${fontSize}px Mariupol`;
    ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
    for (let i = 0; i < 5; ++i) {
      const text = `${scoreMin + (scoreMax - scoreMin) * (i / 4)}`;
      const { width } = ctx.measureText(text);
      let x = p + (i / 4) * w - width / 2;
      if (x - width / 2 < p) {
        x = p;
      }
      if (x + width > W - p) {
        x = W - p - width;
      }
      ctx.fillText(text, x, H - p - down + fontSize);
    }
  }, [score, data]);

  const markerImage = React.useMemo(() => {
    const image = new Image();
    image.src = marker;
    image.onload = render;
    return image;
  }, [marker]);

  React.useEffect(() => {
    render();

    window.addEventListener('resize', render);
    return () => {
      window.removeEventListener('resize', render);
    };
  }, [render]);

  return (
    <div className='MyRatingResultCardChart'>
      <canvas ref={canvas} />
    </div>
  );
};

const ResultCard = ({
  index,
  count,
  emoji,
  header,
  text,
  chart,
  indexImg,
}: {
  index?: number;
  count?: number;

  emoji?: any;
  header?: string;
  text?: string;
  indexImg: number;

  chart?: React.ReactNode;
}) => {
  return (
    <div data-img={indexImg} className='MyRatingResultCard'>
      <div className='MyRatingResultCard_Top'>
        <img src='/emoji/fulllogo.svg' />
        <span className='MyRatingResultCard_Num'>
          {index}/{count}
        </span>
      </div>
      <div className='MyRatingResultCard_Content'>
        <div>
          <img src='/emoji/fire.png' alt='emoji fire' />
          <img src={emoji} alt='emoji' />
          <img src='/emoji/fire.png' alt='emoji fire' />
        </div>
        <h2>{header}</h2>
        <p>{text}</p>
      </div>
      <div className='MyRatingResultCard_Chart'>{chart}</div>
    </div>
  );
};

const LastResultCard = () => {
  return (
    <div className='MyRatingResultCard LastCard'>
      <div className='MyRatingResultCard_Top'>
        <img src='/emoji/fulllogoRedhead.svg' style={{ opacity: 1 }} />
      </div>
      <div className='MyRatingResultCard_Content'>
        <h2>Завітай до нашого інстаграму!</h2>
        <a href='https://www.instagram.com/abitly.io/' target='_blank' className='AppButton' rel='noreferrer'>
          <img src='/emoji/instagram.svg' alt='instagram icon' />
          Instagram
        </a>
      </div>
    </div>
  );
};

const ShareMenu = ({
  open,
  setOpen,
  index,
  length,
}: {
  open?: boolean;
  setOpen: (newState: boolean) => void;
  index: number;
  length: number;
}) => {
  const [copied, setCopied] = React.useState(false);
  const [downloadImg, setDownloadImg] = React.useState(false);

  React.useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setOpen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div className={cx('AppHeaderMenu ShareMenu', { open })}>
      <div>
        <div className='HeaderMenu'>
          <h2>Поділитись</h2>
          <BurgerButton value={open} setValue={setOpen} />
        </div>
        <HeaderMenuGroup
          buttons={[
            index !== length - 0.1 ? (
              <HeaderMenuButton
                emoji='/emoji/download.svg'
                onClick={() => {
                  downloadCard(index, setDownloadImg);
                }}
                name={!downloadImg ? 'Завантажити картинку' : 'Завантажилась!'}
              />
            ) : (
              false
            ),
            <HeaderMenuButton
              emoji='/emoji/copy.svg'
              onClick={() => {
                copyToClipboard(window.location.toString(), setCopied);
              }}
              name={!copied ? 'Скопіювати посилання' : 'Скопійовано!'}
            />,
          ]}
        />
      </div>
    </div>
  );
};

const DonateCard = () => {
  return (
    <AppContent className='DonateCard'>
      <div className='MyRatingDonateCard'>
        <div className='MyRatingDonateCard_Content'>
          <div className='MyRatingDonateCard_Header'>
            <p>
              Розробники abitly теж <span>люди</span> студенти. Тож ми будемо дуже вдячні якщо ти задонатиш нам на
              поїсти в столовці
              <img src='/emoji/hands.png' alt='hands emoji' />
            </p>
          </div>
          <a target='_blank' href='https://send.monobank.ua/jar/3UNSF6txsH' className='AppButton' rel='noreferrer'>
            Дати 22 гривні
          </a>
        </div>
      </div>
    </AppContent>
  );
};

export const MyRatingResult = () => {
  const [open, openDelayed, setOpen] = useAnimated(false);
  const [click, setClick] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const navigate = useNavigate();
  const params: any = useParams();
  const subjects: any = filterSubjects(params.subjects);
  const scores: any = params.scores.split(',');
  const marks = useZNOData(+params.year);

  // @ts-ignore
  // @ts-ignore
  return (
    <div className='MyRatingResult'>
      {/* <DonateCard/> */}
      <Slider
        className='MyRatingResult_Cards'
        afterChange={(index = 0) => {
          setCurrentIndex(index);
        }}
        dots
        slidesToShow={1.1}
        slidesToScroll={1}
        arrows={false}
        infinite={false}
      >
        {subjects.map((subject: any, i: any) => (
          <ResultCard
            /* eslint-disable-next-line react/no-array-index-key */
            key={i}
            indexImg={i}
            index={i + 1}
            count={subjects.length}
            emoji={findSubjectEmoji(subject.subjectName, ratingSubjects)}
            header={`Твій бал ЗНО з ${subject.ratingName} краще ніж у ${
              marks
                ? calculatesZNOPercentage(findSubjectId(subject.subjectName, ratingSubjects), +scores[i], marks)
                : ''
            } абітурієнтів`}
            text={`які здавали цей предмет в ${+params.year} році`}
            chart={
              <ResultCardChart
                score={+scores[i]}
                data={marks !== null ? marks[findSubjectId(subject.subjectName, ratingSubjects)].stats : null}
              />
            }
          />
        ))}
        <LastResultCard />
      </Slider>
      <AppContent className='MyRatingResult_Bottom'>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <img src='/emoji/share.svg' style={{ marginRight: 12 }} />
            Поділитись
          </span>
        </Button>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className='AppButtonLink LinkAgain'
          onClick={() => {
            setClick(true);
            setTimeout(() => {
              navigate('/');
            }, 500);
          }}
        >
          <img className={cx('LinkAgainEmoji', { click })} src='/emoji/again.svg' />
          Пройти ще раз
        </a>
      </AppContent>
      {(open || openDelayed) && (
        <ShareMenu open={open} setOpen={setOpen} length={subjects.length} index={currentIndex} />
      )}
    </div>
  );
};
